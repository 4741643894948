<template>
  <div
    class="toast toast-info flex items-stretch space-x-4"
    :class="[`toast-${item.type}`]"
  >
    <div class="pl-4 flex items-center">
      <FaIcon size="lg" :icon="typeIconMap[item.type]" fixed-width />
    </div>
    <div class="py-4 flex-grow">
      <h3 class="font-semibold">{{ item.title }}</h3>
      <p class="toast-text text-neutral-300 whitespace-pre-line">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-if="item.text" v-html="snarkdown(item.text)" />
        <Spinner
          v-if="item.spinner"
          class="ml-2"
          size="sm"
          light
          inline
        />
      </p>
    </div>
    <div>
      <button
        class="toast-btn whitespace-nowrap p-4 h-full"
        @click="btnAction"
      >
        <FaIcon
          v-if="item.btnLabel === undefined"
          class="text-neutral-500"
          size="lg"
          :icon="faTimes"
          fixed-width
        />
        <template v-else>
          {{ item.btnLabel }}
        </template>
      </button>
    </div>
  </div>
</template>

<script setup>
import Spinner from '../../../shared/components/Spinner.vue'
import { faInfoCircle, faCheckCircle, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { INFO, ERROR, SUCCESS } from '../../store/notifications.js'
import snarkdown from 'snarkdown'

const emit = defineEmits(['dismiss'])
const props = defineProps({
  item: { type: Object, required: true },
})

const typeIconMap = {
  [INFO]: faInfoCircle,
  [ERROR]: faExclamationTriangle,
  [SUCCESS]: faCheckCircle,
}

function btnAction () {
  const defaultAction = () => emit('dismiss')
  if (props.item.btnAction) props.item.btnAction(defaultAction)
  else defaultAction()
}
</script>

<style scoped>
.toast {
  @apply rounded shadow-lg;
  @apply w-max;
  background-color: #211E1C; /* between 800 and 900 */
}
.toast-info {
  --tw-gradient-from: #371155;
  @apply bg-gradient-to-r via-transparent to-transparent;
  @apply text-purple-400;
}
.toast-error {
  --tw-gradient-from: #430F0F;
  @apply bg-gradient-to-r from-red-900 via-transparent to-transparent;
  @apply text-red-400;
}
.toast-success {
  --tw-gradient-from: #0C311B;
  @apply bg-gradient-to-r from-green-900 via-transparent to-transparent;
  @apply text-green-400;
}
.toast-text :deep(a) {
  @apply underline hover:bg-white/20;
}
.toast-btn {
  @apply border-l border-neutral-700 hover:bg-black transition-colors;
  @apply rounded-l-none rounded-r;
}
</style>
