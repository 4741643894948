import TeamParent from '../views/team/Parent.vue'
import { awaitUser, requireAuth } from './middleware.js'

export default [
  //
  // Team Pages
  // ^/team
  //
  {
    path: '/team/',
    name: 'team-parent', // 'profile-parent' -> 'team-parent'
    redirect: { name: 'team-chooser' },
    meta: {
      app: true,
      versionCheck: true,
      context: 'team',
      title: 'Loading Team',
    },
    component: TeamParent,
    beforeEnter: [awaitUser, requireAuth],
    children: [
      {
        path: '',
        name: 'team-chooser', // 'dashboard-forwarder' -> 'team-chooser'
        meta: {
          title: 'Select Team',
          containerClass: 'max-w-2xl',
        },
        component: () => import('../views/team/TeamChooser.vue'),
      },
      {
        path: 'accept-invite/',
        name: 'accept-invite',
        meta: {
          title: 'Accept Invite',
          containerClass: 'max-w-2xl',
        },
        component: () => import('../views/team/AcceptInvite.vue'),
      },
      {
        path: ':teamId/',
        name: 'team',
        meta: {
          title: 'Team',
        },
        component: () => import('../views/team/Team.vue'),
        props: true,
        children: [
          {
            path: 'dashboard/',
            name: 'dashboard',
            meta: {
              title: 'Dashboard',
            },
            component: () => import('../views/team/Dashboard.vue'),
          },
          {
            path: 'devices/',
            name: 'devices',
            meta: {
              title: 'Connected Devices',
              containerClass: 'max-w-6xl',
            },
            component: () => import('../views/team/Devices.vue'),
          },
          {
            path: 'billing/',
            name: 'billing',
            meta: {
              title: 'Billing',
              containerClass: 'max-w-6xl',
            },
            component: () => import('../views/team/Billing.vue'),
          },
          {
            path: 'checkout-pre/',
            name: 'checkout-pre',
            meta: {
              title: 'Checkout',
              containerClass: 'max-w-prose',
              hideTeamSwitcher: true,
            },
            component: () => import('../views/team/CheckoutPre.vue'),
          },
          {
            path: 'checkout/',
            name: 'checkout',
            meta: {
              title: 'Checkout',
              containerClass: 'max-w-6xl',
              hideTeamSwitcher: true,
            },
            component: () => import('../views/team/Checkout.vue'),
          },
          {
            path: 'checkout-success/',
            name: 'checkout-success',
            meta: {
              title: 'Purchase Complete',
              containerClass: 'max-w-6xl',
              hideTeamSwitcher: true,
            },
            component: () => import('../views/team/CheckoutSuccess.vue'),
          },
        ],
      },
    ],
  },

  //
  // Legacy Redirects
  //
  { path: '/profile/', redirect: { name: 'team-chooser' } },
  { path: '/profile/dashboard/', redirect: { name: 'team-chooser' } },
  { path: '/profile/devices/', redirect: { name: 'team-chooser' } },
  { path: '/profile/billing/', redirect: { name: 'team-chooser' } },
  { path: '/profile/settings/', redirect: { name: 'user-settings' } },
]
