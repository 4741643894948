import localStorage from '../../shared/localStorage.js'

// Define a local store to keep track of event timestamps.
const timestamps = {}
const verbosity1 = parseInt(localStorage.getItem('stagetimer:verbosity')) >= 1

/**
 * Resets the stored timestamps for all events.
 * This should be called when connecting to a new channel.
 */
export function reset () {
  for (let key in timestamps) {
    delete timestamps[key]
  }
}

/**
 * Checks if the event received is in the correct order based on timestamp.
 * Events without a timestamp are always considered in order.
 * Logs an info message if the event is out-of-order and verbosity level is 1 or higher.
 *
 * @param {string} event - The identifier of the event.
 * @param {number} t - The timestamp of the event as the number of milliseconds since the Unix epoch.
 * @returns {boolean} Returns true if the event is in order or if no timestamp is provided; otherwise, false.
 */
export function check (event, t) {
  // Return true if no timestamp is provided.
  if (!t) return true

  // Get the last known timestamp for the event.
  const lastT = timestamps[event]

  // Check if the new event is in order.
  const isInOrder = !lastT || t > lastT

  // Update the stored timestamp if the new event is in order or if there's no existing timestamp.
  if (isInOrder) timestamps[event] = t
  else if (verbosity1) console.info(`[OOOService] ignoring out-of-order ${event}`)

  // Return whether the new event is in order.
  return isInOrder
}

/**
 * Registers or updates the timestamp for a specific event.
 *
 * @param {string} event - The identifier of the event.
 * @param {number} t - The timestamp of the event as the number of milliseconds since the Unix epoch.
 * @returns {void}
 */
export function register (event, t) {
  if (!t) return true
  timestamps[event] = t
}
