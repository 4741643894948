<template>
  <main>
    <component
      :is="isApp ? SiteHeaderApp : SiteHeaderStatic"
      :pathname="pathname"
    />
    <section
      class="container mx-auto"
      :class="props.containerClass"
      :style="props.containerStyle"
    >
      <slot />
    </section>
    <div
      v-if="props.hideCta"
      class="container mx-auto px-4 md:px-12 py-6"
    >
      <hr>
    </div>
    <SiteFooter
      :config="config"
      :hide-cta="props.hideCta"
      :hide-go-timers="isApp"
    />
  </main>
</template>

<script setup>
import SiteHeaderStatic from '../../shared/SiteHeader.vue'
import SiteHeaderApp from '../components/SiteHeader.vue'
import SiteFooter from '../../shared/SiteFooter.vue'
import config from '../../shared/configImporter.js'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

const route = useRoute()
const props = defineProps({
  containerClass: { type: [Array, Object, String], default: 'max-w-6xl px-4 md:px-8 pt-24 pb-36' },
  containerStyle: { type: [Object, String], default: () => ({}) },
  hideCta: Boolean,
})
const isApp = computed(() => route.meta.app === true)
const pathname = computed(() => route.path)
</script>
