import { gtag, initDataLayer, install } from 'ga-gtag'

let enabled = false

// analyticsId: 'G-TFL7YL7TFH'
// adsId:       'AW-378151728'

export async function init (analyticsId, adsId) {
  initDataLayer()
  gtag('consent', 'default', {
    'ad_storage': 'granted',
    'analytics_storage': 'granted',
    'ad_user_data': 'granted',
    'ad_personalization': 'denied',
  })
  install(analyticsId)
  gtag('config', adsId)
  enabled = true
}

export function pageview (location) {
  if (!enabled) return
  gtag('event', 'page_view', {
    'page_location': location,
  })
}

export function signup () {
  if (!enabled) return
  gtag('event', 'conversion', {
    'send_to': 'AW-378151728/d702CKfdu88ZELDGqLQB',
  })
}

export function beginCheckout ({
  value = 24.0,
  currency = 'EUR',
}) {
  if (!enabled) return
  gtag('event', 'conversion', {
    'send_to': 'AW-378151728/fg5TCOn0xc8ZELDGqLQB',
    'value': value,
    'currency': currency,
  })
}

export function purchase ({
  value = 24.0,
  currency = 'EUR',
  transactionId = '',
}) {
  if (!enabled) return
  gtag('event', 'conversion', {
    'send_to': 'AW-378151728/QJs7CJGdy88ZELDGqLQB',
    'value': value,
    'currency': currency,
    'transaction_id': transactionId,
  })
}
