<template>
  <div class="fixed top-1 left-1 right-1 z-[101]">
    <transition name="fade">
      <div
        v-if="isLoading"
        class="h-2 bg-sky-500 rounded shadow"
        :style="{ width: (progress * 100) + '%' }"
      />
    </transition>
  </div>
</template>

<script>
import eventBus from '../../utils/eventBus.js'
import _random from 'lodash/random'

// Assume that loading will complete under this amount of time (ms)
const defaultDuration = 2000
// How frequently to update (ms)
const defaultInterval = 100
// 0 - 1. Add some variation to how much the bar will grow at each interval
const variation = 0.5

export default {
  data: () => ({
    isLoading: false,
    progress: 0,
    timeoutId: null,
  }),
  mounted () {
    eventBus.$on('asyncRouteLoadingStart', this.start)
    eventBus.$on('asyncRouteLoadingStop', this.stop)
  },
  unmounted () {
    eventBus.$off('asyncRouteLoadingStart', this.start)
    eventBus.$off('asyncRouteLoadingStop', this.stop)
  },
  methods: {
    start () {
      this.isLoading = true
      this.progress = 0
      this.loop()
    },
    loop () {
      if (this.timeoutId) clearTimeout(this.timeoutId)
      if (this.progress >= 1) return

      const remainder = 1 - this.progress
      const maxDelta = defaultInterval / defaultDuration
      const relativeDelta = maxDelta * remainder
      const delta = _random(relativeDelta * (1 - variation), relativeDelta * (1 + variation))

      this.progress = Math.min(this.progress + delta, 1)
      this.timeoutId = setTimeout(
        this.loop,
        _random(defaultInterval * (1 - variation), defaultInterval * (1 + variation)),
      )
    },
    stop () {
      this.isLoading = false
      this.progress = 100
      clearTimeout(this.timeoutId)
    },
  },
}
</script>
