<template>
  <PageLayout
    class="text-neutral-900"
    :container-class="`${route.meta.containerClass || 'container'} mx-auto px-4 md:px-12 pb-36 min-h-[80vh]`"
    hide-cta
  >
    <router-view />
  </PageLayout>
</template>

<script setup>
import PageLayout from '../../layouts/Page.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
</script>
