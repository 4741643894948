<template>
  <transition name="modal">
    <div
      v-if="open"
      class="fixed z-50 left-0 bottom-0 right-0 px-8"
    >
      <div
        class="flex flex-wrap sm:flex-nowrap gap-4 justify-center sm:justify-between items-center bg-white text-neutral-900 rounded shadow-lg px-4 py-2 mb-8 mx-auto max-w-xl"
      >
        <div class="text-center sm:text-left">
          <h2 class="font-semibold mb-2">🍪 We use Cookies</h2>
          <div class="text-xs text-neutral-500 font-light">
            We use optional cookies to analyze website traffic. See our <a class="link" href="/cookie-policy/">Cookie Policy</a>.
          </div>
        </div>
        <button
          class="flex-none btn-beta btn-ghost py-1 px-0 w-32"
          @click="close"
        >
          No, thanks
        </button>
        <button
          class="flex-none btn-alpha py-1 px-0 w-32"
          @click="accept"
        >
          Accept
        </button>
      </div>
    </div>
  </transition>
</template>

<script setup>
import Cookies from 'js-cookie'
import { consentGiven } from '../consentHandler.js'
import { ref, watch } from 'vue'

const props = defineProps({
  config: { type: Object, default: undefined },
  hide: Boolean,
})

const open = ref(false)
const consent = ref(null)

let initCalled = false
async function init () {
  if (initCalled) return
  initCalled = true
  const currentUrl = new URL(location)
  const cookie_banner = currentUrl.searchParams.get('cookie_banner')
  consent.value = Cookies.get('stagetimer_cookie_consent')
  if (consent.value === '1') consentGiven(props.config)
  if (!consent.value && cookie_banner === null) open.value = true
}

function accept () {
  Cookies.set('stagetimer_cookie_consent', 1, { expires: 730, sameSite: 'Lax' })
  consentGiven(props.config)
  open.value = false
}
function close () {
  Cookies.set('stagetimer_cookie_consent', 0, { expires: 730, sameSite: 'Lax' })
  open.value = false
}

watch(
  () => props.hide,
  (hide) => {
    if (!hide) init()
    else open.value = false
  },
  { immediate: true },
)
</script>

<style scoped>
.modal-enter-from.modal-mask,
.modal-leave-to.modal-mask{
  opacity: 0;
}
.modal-enter-from .modal,
.modal-leave-to .modal{
  transform: scale(0.96);
}
</style>
