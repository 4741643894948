import { formatHmsToDuration, formatTimeOfDay } from '../utils/timeUtils.js'
import _isEqual from 'lodash/isEqual'

let cache = []

export default function createReplacements (
  currentTimer,
  nextTimer,
  currentTimestamp,
  nextTimestamp,
  todFormat = undefined,
  timezone = null,
) {
  const todOptions = { todFormat, timezone, secondsOptional: true }
  const replacements = {
    '$CURRENT_TITLE': currentTimer ? currentTimer.name : '',
    '$CURRENT_SPEAKER': currentTimer ? currentTimer.extra : '',
    '$CURRENT_NOTES': currentTimer ? currentTimer.notes : '',
    '$CURRENT_START': currentTimer ? formatTimeOfDay(currentTimestamp.start, todOptions) : '',
    '$CURRENT_FINISH': currentTimer ? formatTimeOfDay(currentTimestamp.finish, todOptions) : '',
    '$CURRENT_DURATION': currentTimer ? formatHmsToDuration(currentTimer, { includeH: false }) : '',
    '$NEXT_TITLE': nextTimer ? nextTimer.name : '',
    '$NEXT_SPEAKER': nextTimer ? nextTimer.extra : '',
    '$NEXT_NOTE': nextTimer ? nextTimer.notes : '',
    '$NEXT_START': nextTimer ? formatTimeOfDay(nextTimestamp.start, todOptions) : '',
    '$NEXT_FINISH': nextTimer ? formatTimeOfDay(nextTimestamp.finish, todOptions) : '',
    '$NEXT_DURATION': nextTimer ? formatHmsToDuration(nextTimer, { includeH: false }) : '',
  }
  if (!_isEqual(cache, replacements)) cache = replacements
  return cache
}
