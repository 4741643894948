import axios from '../../shared/axios.js'
import { useStagetimer } from '../store/stagetimer.js'


async function checkVersion (router) {
  const route = router.currentRoute.value
  const stagetimer = useStagetimer()
  if (route.meta.versionCheck) {
    const { data } = await axios.get('/version/')
    stagetimer.setServerVersion(data)
  }
}

export default {
  async install (app, { router }) {
    const run = timeout => setTimeout(() => {
      checkVersion(router)
      run(Math.min(timeout * 10, 600000))
    }, timeout)
    run(1000)
  },
}
