import Firebase from '@firebase/app'
import FirebaseSingleton from '../../shared/firebase.js'
import config from '../../shared/configImporter.js'
import { commitReferrers } from '../../shared/referrers.js'
import { useUser } from '../store/user.js'
import { updateUserId } from '../sentry.js'
import sha1 from 'sha1'
import Cookies from 'js-cookie'

export default {
  async install () {
    await FirebaseSingleton.initialize(Firebase, config.firebase)
    const userStore = useUser()

    // Init firebase auth
    await FirebaseSingleton.authenticate()

    // Listen to user state change
    let userHash = null
    FirebaseSingleton.onAuthStateChanged(user => {
      const newUserHash = sha1(JSON.stringify(user))
      if (userHash === newUserHash) return
      userHash = newUserHash
      userStore.onFirebaseAuth(user)
      updateUserId(user?.uid)
      if (user?.uid) {
        commitReferrers(user.email)
        Cookies.set('authenticated', true, { expires: 30, sameSite: 'Lax' })
      } else {
        Cookies.remove('authenticated')
      }
    })
  },
}
