import { timerBehaviors, todFormats, countdownFormats } from '@stagetimerio/shared'
import _isEqual from 'lodash/isEqual'

let cachedMoment = null

/**
 * timerId: ObjectId
 * running: Boolean
 * deadline: Date
 * kickoff: Date
 * lastStop: Date
 * total: Number (milliseconds)
 * left: Number (milliseconds)
 * time: Number (milliseconds)
 * yellow: Number (milliseconds)
 * red: Number (milliseconds)
 * title: String
 * todFormat: String (see @stagetimerio/shared -> todFormats)
 */
export default function createMoment (
  timeset,
  timer,
  now = Date.now(),
  behavior = timerBehaviors.STOP,
  todFormat = todFormats.AUTO,
  countdownFormat = countdownFormats.DEFAULT_COUNTDOWN_FORMAT,
) {
  const tenMin = 60000
  if (now <= 0) now = Date.now()

  // Calculate 'total' (round 10 ms due to inaccuracies)
  const total = timeset.deadline ? timeset.deadline - timeset.kickoff : tenMin

  // Calculate 'left'
  let left
  if (!timeset.deadline) left = tenMin
  else if (timeset.running) left = timeset.deadline - now
  else left = timeset.deadline - timeset.lastStop

  // Correct edge-case where left > total
  if (left > total) left = total

  // Calculate 'time'
  const stop = left <= 0 && behavior === timerBehaviors.STOP

  const moment = {
    ...timeset,
    total: floor50(total),
    left: floor50(left),
    countdown: floor50(stop ? 0 : left),
    countup: floor50(stop ? total : total - left),
    yellow: timer?.yellow || 0,
    red: timer?.red || 0,
    title: timer?.showName ? timer?.name : '',
    extra: timer?.showExtra ? timer?.extra : '',
    todFormat,
    countdownFormat,
  }

  if (!_isEqual(moment, cachedMoment)) cachedMoment = moment
  return cachedMoment
}

function floor50 (num) {
  return Math.floor(num / 50) * 50
}
