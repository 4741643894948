/**
 * Usage example:
 *
 * <a
 * v-email-obfuscate="'gKy'"
 * href="mailto:Peter%20Shaw<petgKyer.gKyshaw@egKyxample.com>?subject=Hey%20Peter"
 * >
 *   pet<b>tvv</b>er.<i>sha</i>w@exam<b>rpt</b>ple.com
 * </a>
 */

function defuscate (event) {
  const el = event.target
  const regex = new RegExp(el.obfuscateSnare, 'g')
  el.href = el.href?.replace(regex, '')
}

const eventListeneerOptions = { once: true }

function create (el, { value }) {
  el.obfuscateSnare = value
  el.addEventListener('focus', defuscate, eventListeneerOptions)
  el.addEventListener('mouseenter', defuscate, eventListeneerOptions)

  for (const child of el.children) {
    if (child.nodeName === 'B') {
      child.style.visibility = 'hidden'
      child.style.fontSize = 0
    }
    if (child.nodeName === 'I') child.style.fontStyle = 'normal'
  }
}

function remove (el) {
  el.removeEventListener('focus', defuscate, eventListeneerOptions)
  el.removeEventListener('mouseenter', defuscate, eventListeneerOptions)
}

const directive = {
  mounted: create,
  updated: create,
  unmounted: remove,
}

let installDone = false

export default {
  install (app) {
    if (installDone) return
    installDone = true

    app.directive('email-obfuscate', directive)
  },
}
