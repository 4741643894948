import { defineStore } from 'pinia'
import { useUser } from './user.js'
import localStorage from '../../shared/localStorage.js'
import { nanoid } from 'nanoid'
import _keyBy from 'lodash/keyBy'

const CLIENTNAME_KEY = 'stagetimer:clientName'

// id: '95439f2e-9886-11ed-bd76-ea824c3b6cbe'
// presence_ref: 'FzvugrM7iWRC51WC' (only for realtime)
// view: 'CONTROLLER'
// name: 'Viewer 1'
// uid: ''
// thumb: ''

export const useClients = defineStore('clients', {
  state: () => ({
    myId: null,
    byId: {},
  }),
  getters: {
    items: state => Object.values(state.byId)
      .sort((a, b) => a.name?.localeCompare(b.name) || 0)
      .sort((a, b) => a.view?.localeCompare(b.view) || 0),
    count: state => state.items.length,
    me: state => state.byId[state.myId],
  },
  actions: {
    setId (id) {
      this.myId = id
    },
    set (clients) {
      this.byId = _keyBy(clients, 'id')
    },
  },
})

export function getStoredName () {
  const user = useUser()
  const storedName = localStorage.getItem(CLIENTNAME_KEY)
  if (storedName) return storedName
  if (user.displayName) return user.displayName
  const id = nanoid(8).replace(/[^a-zA-Z0-9]/g, '').substring(0, 4).toUpperCase()
  return `Device ${id}`
}

export function setStoredName (name) {
  const user = useUser()
  if (!name || name === user.displayName || name === user.email) return
  localStorage.setItem(CLIENTNAME_KEY, name)
}
