import configDefaults from '../config.defaults.json'
import configDev from '../config.dev.json'
import configProd from '../config.prod.json'
import { version } from '../../package.json'

let config
switch (import.meta.env.PUBLIC_CONFIG_ENV || process.env.PUBLIC_CONFIG_ENV) {
  case 'PROD':
  case 'NEXT':
  case 'BACKUP':
    config = configProd
    break
  case 'DEV':
  case 'STAGE':
  default:
    config = configDev
    break
}

export default {
  version,
  ...configDefaults,
  ...config,
}
