import { defineStore } from 'pinia'
import eventBus from '../utils/eventBus.js'
import * as OOOService from '../utils/OutOfOrderService.js'
import _keyBy from 'lodash/keyBy'
import _last from 'lodash/last'
import _sortBy from 'lodash/sortBy'
import _pull from 'lodash/pull'

export const useMessages = defineStore('messages', {
  state: () => ({
    byId: {},
    activeId: null,
    loading: [],
  }),
  getters: {
    get: state => ({
      items: state.items,
      activeId: state.activeId,
    }),
    items: state => _sortBy(Object.values(state.byId), ['index']),
    active: state => state.byId[state.activeId],
    first: state => state.items[0],
    last: state => _last(state.items),
    count: state => state.items.length,
    getById: state => id => state.byId[id],
  },
  actions: {
    set ({ activeId, items }, t) {
      if (!OOOService.check('messages', t)) return
      this.activeId = activeId
      this.byId = _keyBy(items.filter(Boolean), '_id')
    },
    setAll (items, t) {
      if (!OOOService.check('messages', t)) return
      this.byId = _keyBy(items.filter(Boolean), '_id')
    },
    setActiveId (messageId, t) {
      if (!OOOService.check('messages', t)) return
      this.activeId = messageId
    },
    setOne (message, t) {
      if (!OOOService.check(`message-${message._id}`, t)) return
      if (message) this.byId[message._id] = message
    },
    patchOne (message, t) {
      if (!OOOService.check(`message-${message._id}`, t)) return
      Object.assign(this.byId[message._id], message)
    },
    removeOne (messageId) {
      delete this.byId[messageId]
    },
    reorder (idList) {
      idList.forEach((_id, index) => (this.byId[_id].index = index))
    },
    flash (options) {
      const count = isNaN(options?.count) ? 3 : options.count
      eventBus.$emit('messages/flash', count)
    },
    show (message, t) {
      this.setActiveId(message._id, t)
    },
    hide (_, t) {
      this.setActiveId(null, t)
    },
    loadingStart (_id) {
      if (!this.loading.includes(_id)) this.loading.push(_id)
    },
    loadingResolve (_id) {
      this.loading = _pull(this.loading, _id)
    },
  },
})
