<template>
  <div
    v-if="error"
    class="rounded border overflow-x-auto px-2 py-1 space-y-2"
    :class="{
      'bg-red-200 border-red-300 text-red-900': light,
      'bg-red-900 bg-opacity-50 border-red-900 text-white': !light,
    }"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="errorMessage" />
    <template v-if="errorMessage.includes('Network Error')">
      <hr :class="{ 'border-red-300': light, 'border-red-900': !light }">
      <p class="text-xs">
        Unable to connect to the server. Check our <a href="https://stats.uptimerobot.com/jJ140cjvEB" target="_blank" rel="noopener" class="underline">Status Page</a> or try changing your DNS settings to 8.8.8.8 (Google) and 1.1.1.1 (Cloudflare) following <a href="https://www.windowscentral.com/how-change-your-pcs-dns-settings-windows-10" target="_blank" rel="noopener" class="underline">this guide</a>.
      </p>
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  error: { type: [Object, Boolean, String], default: null },
  light: Boolean,
})

const errorMessage = computed(() => {
  if (props.error === null) return ''
  if (typeof props.error === 'boolean') return ''
  if (typeof props.error === 'string') return props.error
  return props.error.response?.data?.message || props.error.response?.data || props.error?.message || props.error
})
</script>
