import axios from 'axios'
import _uniq from 'lodash/uniq'
import _isEqual from 'lodash/isEqual'
import localStorage from './localStorage.js'

export function initReferrers () {
  if (!localStorage.available()) return

  // scan url query and http headers to get referrer
  const searchParams = new URLSearchParams(window.location.search)
  const referrers = [
    searchParams.get('ref'),
    searchParams.get('referer'),
    searchParams.get('referrer'),
    searchParams.get('source'),
    searchParams.get('utm_source'),
    searchParams.get('utm_medium'),
    searchParams.get('utm_campaign'),
    searchParams.get('mtm_campaign'),
    searchParams.get('query'),
    searchParams.get('q'),
    searchParams.get('search'),
  ]

  // get the document referrer if it differs from the current domain
  if (!document.referrer.includes(window.location.hostname)) {
    referrers.push(document.referrer)
  }

  // save referrer to local storrage
  const storedReferrers = getReferrers()
  const uniqReferrers = _uniq([...storedReferrers, ...referrers].filter(Boolean))
  if (!_isEqual(storedReferrers, uniqReferrers)) setReferrers(uniqReferrers)
}

export function initEntrypoint () {
  if (!localStorage.available()) return
  const storedEntrypoint = getEntrypoint()
  if (!storedEntrypoint) setEntrypoint(window.location.pathname)
}

export function setReferrers (val = []) {
  if (!localStorage.available()) return
  localStorage.setItem('referrers', JSON.stringify(val))
}

export function setEntrypoint (val = '') {
  if (!localStorage.available()) return
  localStorage.setItem('stagetimer:entrypoint', String(val))
}

export function getReferrers () {
  if (!localStorage.available()) return []
  return JSON.parse(localStorage.getItem('referrers') || '[]')
}

export function getEntrypoint () {
  if (!localStorage.available()) return ''
  return localStorage.getItem('stagetimer:entrypoint') || ''
}

let lastCalledArg = ''
export async function commitReferrers (email) {
  if (!localStorage.available()) return
  if (lastCalledArg === email) return
  lastCalledArg = email

  // Get stored referrers
  const storedReferrers = getReferrers()
  const storedEntrypoint = getEntrypoint()
  const commitReferers = storedReferrers.length > 0
  const commitEntrypoint = storedEntrypoint && !storedEntrypoint.startsWith('committed')

  // Call firebase function to update airtable
  if (commitReferers || commitEntrypoint) {
    try {
      const functionUrl = import.meta.env.PUBLIC_FUNCTION_V1 + 'upsertAirtableRecord'
      const payload = { 'Email': email }
      if (commitReferers) payload.Referrers = storedReferrers
      if (commitEntrypoint) payload.Entrypoint = storedEntrypoint
      await axios.post(functionUrl, payload)
      if (commitReferers) setReferrers([])
      if (commitEntrypoint) setEntrypoint('committed:' + storedEntrypoint)
    } catch (error) {
      console.error(error)
    }
  }
}
