<template>
  <component
    :is="tag"
    class="logo rounded inline-flex gap-2 items-center"
    :class="{ dark, 'logo-underline': underline }"
    :href="href"
    :to="href"
  >
    <img
      class="inline"
      src="/logo.svg"
      alt="Logo"
      width="30"
      height="30"
    >
    <span
      class="font-semibold whitespace-nowrap"
      :class="{ 'hidden md:inline': responsive }"
    >
      <span>stagetimer</span>
      <span :class="{ '!text-teal-600': !dark, '!text-white': dark }">.io</span>
    </span>
  </component>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  beforeRouteUpdate () {
    this.authenticated = Cookies.get('authenticated')
  },
  props: {
    underline: Boolean,
    responsive: Boolean,
    disableLink: Boolean,
    dark: Boolean,
  },
  data: () => ({
    authenticated: Cookies.get('authenticated'),
  }),
  computed: {
    tag () {
      if (this.disableLink) return 'span'
      if ('$router' in this && this.authenticated) return 'router-link'
      return 'a'
    },
    href () {
      return this.authenticated ? '/profile/dashboard/' : '/'
    },
  },
}
</script>

<style scoped>
.logo-underline {
  text-decoration-color: theme('colors.teal.500');
}
.logo-underline.dark {
  text-decoration-color: theme('colors.white');
}
.logo-underline:hover {
  text-decoration-line: underline;
}
.logo > img {
  height: 1.5em;
}
</style>
