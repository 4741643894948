/**
 * Inject the Sentry dependency like so:
 *
 *   import * as Sentry from '@sentry/vue'
 *   import { initSentry } from './sentry.js'
 *   initSentry(Sentry)
 */
import { version } from '../package.json'

let Sentry

export function initSentry (_Sentry, app, router) {
  Sentry = _Sentry
  Sentry.init({
    app,
    dsn: import.meta.env.PUBLIC_SENTRY_DSN,
    release: 'stagetimer@' + version,
    environment: import.meta.env.PUBLIC_SENTRY_ENV,
    integrations: [
      // vue-router integration, see: https://docs.sentry.io/platforms/javascript/guides/vue/features/vue-router/
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'timeout',
      'TimeoutError',
      'xhr poll error',
      'Unable to preload CSS',
      // 'Failed to fetch dynamically imported module',
      'Access is denied for this document',
      'Network Error',
      'Passwords do not match',
      'WakeLock',
      'safari-extension',
      'chrome-extension',
    ],

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing
    tracesSampleRate: 0.05,

    // Capture Replay for 1% of all sessions, plus for 20% of sessions with an error
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.2,
  })
}


export function updateUserId (userId) {
  if (!Sentry) return
  Sentry.setUser({ id: userId })
}

export function captureException (error, contexts = {}) {
  if (!Sentry) return
  Sentry.captureException(error, { contexts })
  if (import.meta.env.DEV) console.debug(error)
}
