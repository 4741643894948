import _kebabCase from 'lodash/kebabCase'
import _omit from 'lodash/omit'

export function toPlainLabel (label) {
  return _omit(label, ['id', '_id'])
}

export function hashLabel (label) {
  return _kebabCase([label.name, label.color].join(' '))
}

export function uniqueLabels (list) {
  const set = new Set()
  list.forEach(label => set.add(JSON.stringify(toPlainLabel(label))))
  return Array.from(set).map(label => JSON.parse(label))
}
