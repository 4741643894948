import io from 'socket.io-client/dist/socket.io.js'

/**
 * Note: Originally I planned to introduce some jitter when the socket attempts reconnects.
 *       But since the load on the server on socket connections is much lighter now, I can
 *       probably skip this idea for now.
 */

const verbosity1 = parseInt(localStorage.getItem('stagetimer:verbosity')) >= 1

const $private = {
  socket: null,
}

export default class TransportSocketio {
  static init (listenCallback) {
    if (verbosity1) console.info('[TransportSocketio] init()')
    $private.socket = io(import.meta.env.PUBLIC_SOCKET_HOST, {
      path: import.meta.env.PUBLIC_SOCKET_PATH || '/socket.io/',
      autoConnect: false,
      timeout: 5000,
      auth: {},
    })
    $private.socket.onAny((event, payload, t) => {
      if (verbosity1) console.info('[TransportSocketio] listen', { event, t })
      listenCallback(event, payload, t)
    })
    $private.socket.on('connect', () => listenCallback('connect'))
    $private.socket.on('connect_error', () => listenCallback('disconnect'))
    $private.socket.on('disconnect', () => listenCallback('disconnect'))
  }

  static async connect (channelId, client) {
    if (verbosity1) console.info('[TransportSocketio] connect()')
    Object.assign($private.socket.auth, { channelId, client })
    $private.socket.connect()
  }

  static async disconnect () {
    if (verbosity1) console.info('[TransportSocketio] disconnect()')
    $private.socket.disconnect()
  }

  static async updateClient (client) {
    if (verbosity1) console.info('[TransportSocketio] updateClient()')
    $private.socket.emit('updateClient', client)
  }

  static async broadcast (event, payload) {
    if (verbosity1) console.info('[TransportSocketio] broadcast()')
    $private.socket.emit(event, payload)
  }
}
