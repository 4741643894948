<template>
  <nav class="leading-loose">
    <h3 class="font-semibold">{{ title }}</h3>
    <ul>
      <li v-for="(url, label) in links" :key="label">
        <a
          :href="url"
          :class="{ active: isActive(url) }"
          rel="noopener"
          :target="url.startsWith('http') ? '_blank' : '_self'"
        >
          {{ label }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    links: { type: Object, default: () => ({}) },
    pathname: { type: String, default: '/' },
  },
  methods: {
    isActive (path) {
      return this.pathname.startsWith(path)
    },
  },
}
</script>

<style scoped>
li > a {
  @apply font-light text-neutral-600 hover:underline;
}
</style>
