import { addHmsToDate } from './timeUtils.js'
import { timerTypes, timerTriggers } from '@stagetimerio/shared'
import { hmsToMilliseconds, parseDateAsToday, applyDate } from '@stagetimerio/timeutils'
import _isEqual from 'lodash/isEqual'
import { addMinutes } from 'date-fns/addMinutes'

let cache = []

/**
 * Returns an array of timestamps for each timer
 * @param  {object[]} timers
 * @param  {object}   timeset
 * @param  {string}   [timezone]
 * @param  {Date}     [now]
 * @return {object[]}
 */
export default function createTimestamps (
  timers,
  timeset,
  timezone,
  now = new Date(),
) {
  let prevTimestamp = {
    timerId: null,
    start: null,
    finish: null,
    gap: 0,
    compensation: 0,
    delay: 0,
    carry: 0,
    duration: 0,
    explicitStart: false, // if user chose a specific time or it's implied from previous timers
    explicitFinish: false, // if user chose a specific time or it's implied from start time
    prevFinish: null,
  }
  const timestamps = timers.map((timer) => {
    const timestamp = { ...prevTimestamp, timerId: timer._id, prevFinish: prevTimestamp.finish }
    const isActive = timeset.timerId === timer._id
    const isAdvanded = isActive && (timeset.kickoff !== timeset.lastStop)
    const isRunning = isActive && timeset.running

    //
    // Calculate start
    //
    timestamp.explicitStart = Boolean(timer.startTime)
    if (timer.startTime || prevTimestamp.finish) {
      // First we get the time
      const start = timer.startTime || prevTimestamp.finish
      // Then we give allow for max 30m overlaps of timers
      const prevFinishMinus30 = prevTimestamp.finish ? addMinutes(prevTimestamp.finish, -30) : undefined
      // Force the date to be parsed in relation to prevFinish if available (including 30m overlap)
      // This makes sure the continuity is preserved if previous hard dates are in the bast
      const opts = {
        timezone,
        after: prevFinishMinus30,
        now: prevFinishMinus30,
      }
      timestamp.start = parseDateAsToday(start, opts)
      // Then we apply the date if explicitly defined
      if (timer.startDate) {
        timestamp.start = applyDate(timestamp.start, timer.startTime, timezone)
      }
    // In the absense of a startTime we take the kickoff for running timers
    } else if (isRunning || isAdvanded) {
      timestamp.start = new Date(timeset.kickoff)
    }

    //
    // Calculate finish
    //
    switch (timer.type) {
      case timerTypes.FINISH_TIME: {
        // First we get the time
        timestamp.explicitFinish = true
        const opts = { timezone, after: timestamp.start || undefined }
        timestamp.finish = parseDateAsToday(timer.finishTime, opts)
        // Then we apply the date if explicitly defined
        if (timer.finishDate) {
          timestamp.finish = applyDate(timestamp.finish, timer.finishTime, timezone)
        }
        break
      }
      case timerTypes.DURATION:
      default: {
        // Just add the duration to the start time
        timestamp.explicitFinish = false
        timestamp.finish = addHmsToDate(timestamp.start, timer)
        break
      }
    }

    //
    // Calculate gap
    //
    if (prevTimestamp.finish) {
      timestamp.gap = timestamp.start - prevTimestamp.finish
    }

    //
    // Calculate delay
    //
    if (isRunning) {
      // timer is running
      if (timestamp.start) timestamp.delay += timeset.kickoff - timestamp.start
      else timestamp.delay += timeset.deadline - timestamp.finish
    } else {
      timestamp.delay = prevTimestamp.carry
    }

    // TODO: handle negative delay
    timestamp.delay = Math.max(timestamp.delay, 0)

    // Round to 500ms
    timestamp.delay = Math.floor(timestamp.delay / 500) * 500

    //
    // Calculate compensation
    // (skip this if it's the active timer)
    //
    if (
      timestamp.delay > 0
      && timer.trigger !== timerTriggers.LINKED
      && !isActive
    ) {
      if (timestamp.gap > 0) {
        timestamp.compensation = Math.min(timestamp.delay, timestamp.gap)
      } else {
        timestamp.compensation = timestamp.gap
      }
    }

    //
    // Deduct compensation from delay
    //
    timestamp.delay = Math.max(timestamp.delay - timestamp.compensation, 0)

    //
    // Calculate carry
    // Note: If FINISH_TIME is explicit, don't add negative duration to carry (if finishTime before startTime)
    //
    timestamp.carry = timestamp.delay
    if (timer.type === timerTypes.FINISH_TIME) {
      const duration = timestamp.finish - timestamp.start
      if (duration > 0) timestamp.carry = Math.max(timestamp.delay - duration, 0)
    }
    // If timer goes over time, add that to the carry
    if (isActive && timestamp.start && now > (timestamp.finish + timestamp.delay)) {
      timestamp.carry += now - timestamp.finish
    }

    //
    // Calculate duration
    //
    timestamp.duration = hmsToMilliseconds(timer)
    if (timer.type === timerTypes.FINISH_TIME) {
      const start = isAdvanded ? timeset.kickoff : (timestamp.start || now)
      timestamp.duration = timestamp.finish - start
    }

    Object.assign(prevTimestamp, timestamp)
    return timestamp
  })

  // Use cached object if possible to avoid component updates
  if (!_isEqual(cache, timestamps)) cache = timestamps
  return cache
}
