import { defineStore } from 'pinia'
import { useTimers } from './timers.js'
import { useTimeset } from './timeset.js'
import { useRoom } from './room.js'
import { useStagetimer } from './stagetimer.js'
import createTimestamps from '../utils/createTimestamps.js'
import { addMilliseconds } from 'date-fns/addMilliseconds'
import { isSameDay } from '@stagetimerio/timeutils'

export const useTimestamps = defineStore('timestamps', {
  getters: {
    all: () => {
      const timers = useTimers()
      const timeset = useTimeset()
      const room = useRoom()
      const stagetimer = useStagetimer()
      return createTimestamps(timers.items, timeset.get, room.settings.timezone, stagetimer.getNow())
    },
    currentIndex: (state) => {
      const timeset = useTimeset()
      return state.all.findIndex(item => String(item.timerId) === String(timeset.timerId))
    },
    previous: (state) => state.all[state.currentIndex - 1],
    current: (state) => state.all[state.currentIndex],
    next: (state) => state.all[state.currentIndex + 1],
    isMultiDay: (state) => {
      const room = useRoom()
      return isMultiDay(state.all, room.settings.timezone)
    },

    /**
     * Returns a getter to re-calculate once specific timestamp on the fly based on a provided timer object
     */
    getAdjustedTimestamp: () => {
      const timers = useTimers()
      const timeset = useTimeset()
      const room = useRoom()
      const stagetimer = useStagetimer()

      return (timer) => {
        const i = timers.items.findIndex((item) => item._id === timer._id)
        const adjustedItems = timers.items.map((item, j) => i === j ? timer : item)
        const timestamps = createTimestamps(adjustedItems, timeset.get, room.settings.timezone, stagetimer.getNow())
        return timestamps[i]
      }
    },
  },
})

function isMultiDay (timestamps, timezone) {
  if (timestamps.length < 1) return false
  const first = timestamps[0]
  const last = timestamps[timestamps.length - 1]
  const duration = timestamps.reduce((total, timer) => total + timer.duration, 0)

  const start = first.start || new Date()
  const end = last.finish || addMilliseconds(start, duration)

  return !isSameDay(start, end, timezone)
}
