import { electronConstants } from '@stagetimerio/shared'

const isDisabled = import.meta.env.SSR || import.meta.env.PUBLIC_CONFIG_ENV === 'ELECTRON'

/**
 * initialize firebase and return the instance
 * @return {Promise<Object>}
 * @idempotent
 */
let Firebase = undefined
let firebaseInstance = undefined
export async function initialize (injectedFirebase, config) {
  if (isDisabled) return undefined
  if (firebaseInstance) return firebaseInstance
  let resolveMe = null
  Firebase = injectedFirebase
  firebaseInstance = new Promise(r => resolveMe = r)

  const firebaseConfig = {
    apiKey: import.meta.env.PUBLIC_FIREBASE_API_KEY,
    ...config,
  }

  // TODO refactor me
  // const { firebase } = await import('@firebase/app')

  resolveMe(Firebase.initializeApp(firebaseConfig))
  await authenticate()
  return firebaseInstance
}

/**
 * Authenticate with firebase and get the user
 * You can do `await FirebaseSingleton.authenticate()` to wait until auth is finished
 * @idempotent
 */
let authUser = undefined
export async function authenticate () {
  if (isDisabled) return null
  if (authUser !== undefined) return authUser
  let resolveMe = null
  authUser = new Promise(r => resolveMe = r)


  // Listen to auth initialization
  onAuthStateChanged(user => {
    authUser = Promise.resolve(user)
    resolveMe(user)
  })

  return authUser
}

/**
 * Make sure the callback always get's called at least once
 * Note: My Chromium test did never fire this callback
 * @param  {Function} callback
 * @return {void}
 */
let callbackCalled = false
export async function onAuthStateChanged (callback) {
  const firebaseAuth = await getAuth()

  // Listen to auth initialization
  firebaseAuth.onAuthStateChanged(user => {
    callback(user)
    callbackCalled = true
  })

  // Timeout after 3 seconds
  if (callbackCalled) {
    callback(firebaseAuth.currentUser)
  } else {
    setTimeout(() => {
      if (callbackCalled) return
      callback(firebaseAuth.currentUser)
      callbackCalled = true
    }, 3000)
  }
}

export async function getIdToken () {
  if (isDisabled) return electronConstants.uid
  const user = await authenticate()
  if (user) return await user.getIdToken()
  else return null
}

/**
 * get the firebase instance
 * @return {Promise<Object>}
 * @idempotent
 */
export async function getInstance () {
  if (isDisabled) return undefined
  if (firebaseInstance === undefined) throw new Error('Firebase instance is undefined: Call initialize() first')
  return firebaseInstance
}

/**
 * get firebase auth instance
 * @return {Promise<Object>}
 * @idempotent
 */
let authInstance = undefined
export async function getAuth () {
  if (isDisabled) return undefined
  if (authInstance) return authInstance
  let resolveMe = null
  authInstance = new Promise(r => resolveMe = r)

  await import('@firebase/auth')
  const firebase = await getInstance()
  resolveMe(firebase.auth())

  return authInstance
}

export async function getAuthProviders () {
  if (isDisabled) return undefined
  if (firebaseInstance === undefined) throw new Error('Firebase instance is undefined: Call initialize() first')
  await firebaseInstance
  return Firebase.auth
}

/**
 * get firebase firestore instance
 * @return {Promise<Object>}
 * @idempotent
 */
// Due to a Astro error I cannot import the firestore here
let firestoreInstance = undefined
export async function getFirestore () {
  if (isDisabled) return undefined
  if (firestoreInstance) return firestoreInstance
  let resolveMe = null
  firestoreInstance = new Promise(r => resolveMe = r)

  await import('@firebase/firestore')
  const firebase = await getInstance()
  resolveMe(firebase.firestore())
  return firestoreInstance
}

/**
 * get firebase storage instance
 * @return {Promise<Object>}
 * @idempotent
 */
let storageInstance = undefined
export async function getStorage () {
  if (isDisabled) return undefined
  if (storageInstance) return storageInstance
  let resolveMe = null
  storageInstance = new Promise(r => resolveMe = r)

  await import('@firebase/storage')
  const firebase = await getInstance()
  resolveMe(firebase.storage())
  return storageInstance
}

/**
 * get firebase remoteConfig instance
 * @return {Promise<Object>}
 * @idempotent
 */
let remoteConfigInstance = undefined
export async function getRemoteConfig () {
  if (isDisabled) return undefined
  if (remoteConfigInstance) return remoteConfigInstance
  let resolveMe = null
  remoteConfigInstance = new Promise(r => resolveMe = r)

  await import('@firebase/remote-config')
  const firebase = await getInstance()
  resolveMe(firebase.remoteConfig())
  return remoteConfigInstance
}

export default {
  initialize,
  authenticate,
  onAuthStateChanged,
  getIdToken,
  getInstance,
  getAuth,
  getAuthProviders,
  getFirestore,
  getStorage,
  getRemoteConfig,
}
