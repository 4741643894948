<template>
  <header
    class="relative z-20 py-6"
    :class="{
      'text-white': dark,
      'text-neutral-800': !dark,
    }"
  >
    <div
      class="container max-w-screen-xl mx-auto px-4 md:px-12 flex items-center justify-between space-x-4 md:space-x-6 hide-duplicate"
      :class="[containerClass, { 'drop-shadow-lg': dark }]"
    >
      <SiteLogo
        class="flex-none md:text-xl"
        :dark="dark"
        underline
      />

      <!-- Links for anonymous users -->
      <div
        class="flex-auto hidden md:flex justify-center gap-3"
      >
        <!-- Product -->
        <Dropdown
          dropdown-class="-ml-5"
          hover
        >
          <template v-slot:button="{ show }">
            <button
              class="menu-link"
              :class="{ dark }"
              @mouseenter="show"
              @focus="show"
            >
              <span class="mr-1">Product</span>
              <FaIcon :icon="faCaretDown" size="xs" />
            </button>
          </template>

          <div class="text-left">
            <a
              class="block dropdown-link px-4 py-2"
              :class="{ 'font-semibold': isActive('/features/') }"
              href="/features/"
            >Features</a>
            <a
              class="block dropdown-link px-4 py-2"
              :class="{ 'font-semibold': isActive('/offline-version/') }"
              href="/offline-version/"
            >Offline Version</a>
          </div>
        </Dropdown>

        <!-- Pricing -->
        <a
          class="menu-link"
          :class="{ dark, 'font-semibold': isActive('/pricing/') }"
          href="/pricing/"
        >
          Pricing
        </a>

        <!-- Use Cases -->
        <Dropdown
          class="hidden lg:block"
          dropdown-class="-ml-5"
          hover
        >
          <template v-slot:button="{ show }">
            <button
              class="menu-link"
              :class="{ dark, 'font-semibold': isActive('/use-cases/', false) }"
              @mouseenter="show"
              @focus="show"
            >
              <span class="mr-1">Use Cases</span>
              <FaIcon :icon="faCaretDown" size="xs" />
            </button>
          </template>

          <div class="text-left">
            <a
              class="block dropdown-link px-4 py-2"
              :class="{ 'font-semibold': isActive('/use-cases/online-presentation-timer/') }"
              href="/use-cases/online-presentation-timer/"
            >
              Presentations
            </a>
            <a
              class="block dropdown-link px-4 py-2"
              :class="{ 'font-semibold': isActive('/use-cases/meeting-timer/') }"
              href="/use-cases/meeting-timer/"
            >
              Meetings
            </a>
            <a
              class="block dropdown-link px-4 py-2"
              :class="{ 'font-semibold': isActive('/use-cases/online-timer-for-livestreams/') }"
              href="/use-cases/online-timer-for-livestreams/"
            >
              Livestreams
            </a>
            <a
              class="block dropdown-link px-4 py-2"
              :class="{ 'font-semibold': isActive('/use-cases/timer-for-video-productions/') }"
              href="/use-cases/timer-for-video-productions/"
            >
              Video Production
            </a>
            <a
              class="block dropdown-link px-4 py-2"
              :class="{ 'font-semibold': isActive('/use-cases/event-production-timer/') }"
              href="/use-cases/event-production-timer/"
            >
              Events
            </a>
            <a
              class="block dropdown-link px-4 py-2"
              href="/#use-cases"
            >
              More...
            </a>
          </div>
        </Dropdown>

        <!-- Reference -->
        <Dropdown
          dropdown-class="-ml-5"
          hover
        >
          <template v-slot:button="{ show }">
            <button
              class="menu-link"
              :class="{ dark, 'font-semibold': isActive('/docs/', false) || isActive('/blog/', false) }"
              @mouseenter="show"
              @focus="show"
            >
              <span class="mr-1">Resources</span>
              <FaIcon :icon="faCaretDown" size="xs" />
            </button>
          </template>

          <div class="text-left">
            <a
              class="block dropdown-link px-4 py-2"
              :class="{ 'font-semibold': isActive('/media/', false) }"
              href="/media/"
            >
              What experts say
            </a>
            <a
              class="block dropdown-link px-4 py-2"
              :class="{ 'font-semibold': isActive('/docs/', false) }"
              href="/docs/"
            >
              Documentation
            </a>
            <a
              class="block dropdown-link px-4 py-2"
              :class="{ 'font-semibold': isActive('/blog/', false) }"
              href="/blog/"
            >
              Blog
            </a>
          </div>
        </Dropdown>
      </div>

      <!-- Login / Dashboard Link -->
      <div class="space-x-3 hidden md:flex items-center">
        <template v-if="authenticated">
          <a
            data-label="go-to-dashboard-button"
            class="flex items-center btn border py-0 h-10"
            :class="{
              'border-white/0 hover:border-white bg-black/40': dark,
              'border-black/0 hover:border-teal-500 bg-black/10': !dark,
            }"
            href="/team/"
          >
            <span class="lg:hidden mr-2">Login</span>
            <span class="hidden lg:block mr-2">Dashboard</span>
            <FaIcon :icon="faArrowRight" size="xs" />
          </a>
        </template>
        <template v-else>
          <a
            data-label="signin-button"
            class="menu-link"
            :class="{ dark, active: isActive('/auth/signin/') }"
            href="/auth/signin/"
          >
            <span>Sign in</span>
          </a>
          <a
            data-label="signup-button"
            class="btn border py-0 px-3 h-10 leading-10 hidden lg:block"
            :class="{
              'border-white/0 hover:border-white bg-black/40': dark,
              'border-black/0 hover:border-teal-500 bg-black/10': !dark,
              active: isActive('/auth/signup/'),
            }"
            href="/auth/signup/"
          >
            <span>Sign up</span>
          </a>
        </template>
      </div>

      <button
        class="btn h-10 w-10 p-0 md:hidden"
        :class="{
          'border-white/40 hover:border-white': dark,
          'border-black/30 hover:border-black/70': !dark,
        }"
        aria-label="Open site menu"
        @click="menuModalOpen = !menuModalOpen"
      >
        <FaIcon :icon="faBars" />
      </button>
    </div>

    <!-- Mobile Menu -->
    <MobileMenu
      v-model:open="menuModalOpen"
      :pathname="pathname"
      :items="[
        { heading: 'Product' },
        { href: '/features/', label: 'Features' },
        { href: '/offline-version/', label: 'Offline Version' },
        { href: '/pricing/', label: 'Pricing' },
        { heading: 'Use Cases' },
        { href: '/use-cases/online-presentation-timer/', label: 'Presentations' },
        { href: '/use-cases/meeting-timer/', label: 'Meetings' },
        { href: '/use-cases/online-timer-for-livestreams/', label: 'Livestreams' },
        { href: '/use-cases/timer-for-video-productions/', label: 'Video Production' },
        { href: '/use-cases/event-production-timer/', label: 'Events' },
        { href: '/#use-cases', label: 'More...' },
        { heading: 'Resources' },
        { href: '/media/', label: 'What experts say' },
        { href: '/docs/', label: 'Documentation' },
        { href: '/blog/', label: 'Blog' },
      ]"
    >
      <template v-if="authenticated">
        <a
          class="flex items-center rounded h-10 leading-10"
          href="/team/"
        >
          <span class="mr-2">Dashboard</span>
          <FaIcon :icon="faArrowRight" size="xs" />
        </a>
      </template>
      <template v-else>
        <a
          class="block rounded h-10 leading-10"
          href="/auth/signin/"
        >
          Sign in
        </a>
        <a
          class="block rounded h-10 leading-10"
          href="/auth/signup/"
        >
          Sign up
        </a>
      </template>
    </MobileMenu>
  </header>
</template>

<script setup>
import SiteLogo from './components/SiteLogo.vue'
import Dropdown from './components/Dropdown.vue'
import MobileMenu from './components/MobileMenu.vue'
import FaIcon from './components/FaIcon.vue'
import { faCaretDown, faBars, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'
import { ref } from 'vue'

const props = defineProps({
  pathname: { type: String, default: '/' },
  containerClass: { type: String, default: '' },
  dark: Boolean,
})
const authenticated = Cookies.get('authenticated') === 'true'
const menuModalOpen = ref(false)

function isActive (path, exact = true) {
  if (exact) return path === props.pathname
  return props.pathname.startsWith(path)
}
</script>

<style scoped>
.menu-link {
  @apply whitespace-nowrap rounded py-1 px-3;
  text-decoration-color: theme('colors.teal.500');
}
.menu-link:hover,
.menu-link.active {
  text-decoration: underline;
  text-decoration-color: theme('colors.teal.500');
}
.menu-link.dark,
.menu-link.dark:hover,
.menu-link.dark.active {
  text-decoration-color: theme('colors.white');
}
.dropdown-link {
  @apply rounded transition-colors;
}
.dropdown-link:hover {
  @apply bg-neutral-200;
}
/* Astro renders the header twice during dev */
.hide-duplicate ~ .hide-duplicate {
  @apply hidden;
}
</style>
