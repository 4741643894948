import { defineStore } from 'pinia'
import { useRoom } from './room.js'
import { useTimeset } from './timeset.js'
import { useTimestamps } from './timestamps.js'
import { timerChimes } from '@stagetimerio/shared'
import createReplacements from '../utils/createReplacements.js'
import eventBus from '../utils/eventBus.js'
import * as OOOService from '../utils/OutOfOrderService.js'
import { uniqueLabels } from '../utils/labelUtils.js'
import _keyBy from 'lodash/keyBy'
import _last from 'lodash/last'
import _sortBy from 'lodash/sortBy'
import _pull from 'lodash/pull'

export const useTimers = defineStore('timers', {
  state: () => ({
    byId: {},
    loading: [],
  }),
  getters: {
    get: state => ({
      items: state.items,
    }),
    items: state => _sortBy(Object.values(state.byId), ['index']),
    itemIds: state => state.items.map(item => item._id),
    first: state => state.items[0],
    last: state => _last(state.items),
    activeIndex: state => {
      const timerset = useTimeset()
      return state.itemIds.findIndex(id => String(id) === String(timerset.timerId))
    },
    active: state => state.items[state.activeIndex] || undefined,
    previous: state => state.items[state.activeIndex - 1] || undefined,
    next: state => state.items[state.activeIndex + 1] || undefined,
    count: state => state.items.length,
    hasChimes: state => state.items.some(({ chime }) => [chime.yellow, chime.red, chime.zero].some(val => val !== timerChimes.NONE)),
    getById: state => id => state.items?.[id],
    replacements: state => {
      const timestamps = useTimestamps()
      const room = useRoom()
      return createReplacements(
        state.active,
        state.next,
        timestamps.current,
        timestamps.next,
        room.settings.timer?.todFormat,
        room.settings.timezone,
      )
    },
    aggregateLabels: (state) => {
      const labels = state.items.reduce((acc, timer) => {
        if (timer.labels && Array.isArray(timer.labels)) return acc.concat(timer.labels)
        return acc
      }, [])
      return uniqueLabels(labels)
    },
  },
  actions: {
    set (payload, t) {
      if (!OOOService.check('timers', t)) return
      this.byId = _keyBy(payload.items.filter(Boolean), '_id')
    },
    setItems (items, t) {
      if (!OOOService.check('timers', t)) return
      this.byId = _keyBy(items.filter(Boolean), '_id')
    },
    setOne (timer, t) {
      if (!OOOService.check(`timer-${timer._id}`, t)) return
      if (timer) this.byId[timer._id] = timer
    },
    patchOne (timer, t) {
      if (!OOOService.check(`timer-${timer._id}`, t)) return
      Object.assign(this.byId[timer._id], timer)
    },
    removeOne (timerId) {
      delete this.byId[timerId]
    },
    reorder (idList) {
      idList.forEach((_id, index) => (this.byId[_id].index = index))
    },
    flash ({ count = 3 } = {}) {
      eventBus.$emit('timers/flash', count)
    },
    loadingStart (_id) {
      if (!this.loading.includes(_id)) this.loading.push(_id)
    },
    loadingResolve (_id) {
      this.loading = _pull(this.loading, _id)
    },
  },
})
