import PlanParent from '../views/plan/Parent.vue'
import { awaitUser, requireAuth } from './middleware.js'

export default [
  {
    path: '/plan/:entityId/',
    name: 'plan-parent',
    props: true,
    meta: {
      app: true,
      versionCheck: true,
      context: 'plan',
    },
    component: PlanParent,
    beforeEnter: [awaitUser, requireAuth],
    children: [
      {
        path: 'cancel/',
        name: 'plan-cancel',
        meta: {
          title: 'Cancel Plan',
          containerClass: 'max-w-3xl',
        },
        component: () => import('../views/plan/CancelSub.vue'),
      },
      {
        path: 'change-seats/',
        name: 'plan-change-seats',
        meta: {
          title: 'Change Seats',
          containerClass: 'max-w-3xl',
        },
        component: () => import('../views/plan/ChangeSeats.vue'),
      },
      {
        path: 'preview-change/',
        name: 'plan-preview-change',
        meta: {
          title: 'Preview Plan Change',
          containerClass: 'max-w-3xl',
        },
        component: () => import('../views/plan/PreviewPlanChange.vue'),
      },
      {
        path: 'update-payment-method/',
        name: 'plan-update-payment-method',
        meta: {
          title: 'Update Payment Method',
          containerClass: 'max-w-3xl',
        },
        component: () => import('../views/plan/UpdatePaymentMethod.vue'),
      },
      {
        path: 'upgrade-event/',
        name: 'plan-upgrade-event',
        meta: {
          title: 'Upgrade to Premium',
          containerClass: 'max-w-4xl',
        },
        component: () => import('../views/plan/UpgradeEvent.vue'),
      },
    ],
  },
]
