import AuthParent from '../views/auth/Parent.vue'
import { awaitUser } from './middleware.js'

export default [
  //
  // Auth Pages
  // ^/auth
  //
  {
    path: '/auth/',
    name: 'authParent',
    redirect: { name: 'signin' },
    component: AuthParent,
    meta: {
      app: true,
      context: 'auth',
      title: 'Auth',
    },
    beforeEnter: [awaitUser],
    children: [
      {
        path: 'login/',
        name: 'login',
        redirect: { name: 'signin' },
      },
      {
        path: 'signin/',
        name: 'signin',
        meta: {
          title: 'Sign In',
        },
        component: () => import('../views/auth/Signin.vue'),
      },
      {
        path: 'signup/',
        name: 'signup',
        meta: {
          title: 'Sign Up',
        },
        component: () => import('../views/auth/Signup.vue'),
      },
      {
        path: 'logout/',
        name: 'logout',
        redirect: { name: 'signout' },
      },
      {
        path: 'signout/',
        name: 'signout',
        component: () => import('../views/auth/Signout.vue'),
      },
      {
        path: 'paddle-retain-test/',
        name: 'paddle-retain-test',
        component: () => import('../views/auth/PaddleRetain.vue'),
      },
    ],
  },
]
