import { defineStore } from 'pinia'

// Types
export const INFO = 'info'
export const ERROR = 'error'
export const SUCCESS = 'success'
export const notificationTypes = { INFO, ERROR, SUCCESS }

// Durations
export const INFINITE = Infinity
export const SHORT = 2000
export const LONG = 5000
export const notificationDurations = { INFINITE, SHORT, LONG }

export const useNotifications = defineStore('notifications', {
  state: () => ({
    notifications: {},
    increment: 1,
  }),
  getters: {
    all: state => Object.values(state.notifications),
  },
  actions: {
    add (notification) {
      const id = notification.id
      this.notifications[id] = notification
    },
    remove (id) {
      delete this.notifications[id]
    },
    trigger (notification) {
      const item = {
        id: this.increment++,
        type: ERROR,
        title: 'Error',
        text: 'Seems like the developer forgot to include a meaningful message 🤷',
        spinner: false,
        duration: SHORT,
        btnAction: undefined,
        btnLabel: undefined,
        ...notification,
      }
      this.add(item)
      if (item.duration !== INFINITE) {
        setTimeout(() => {
          this.remove(item.id)
        }, item.duration)
      }
      console.info(`[notifications] trigger ${item.type}`, { title: item.title, text: item.text })
      return item.id
    },
    error (payload) {
      return this.trigger({ type: ERROR, ...payload })
    },
    info (payload) {
      return this.trigger({ type: INFO, text: '', ...payload })
    },
    success (payload) {
      return this.trigger({ type: SUCCESS, text: '', ...payload })
    },
    httpError (err, payload) {
      console.error(err)
      return this.trigger({
        type: ERROR,
        title: err.response?.statusText || err.response?.data?.name || err,
        text: err.response?.data?.message || err.response?.data || err?.message,
        ...payload,
      })
    },
  },
})
