import UserParent from '../views/user/Parent.vue'
import { awaitUser, requireAuth } from './middleware.js'

export default [
  //
  // User Pages
  // ^/user
  //
  {
    path: '/user/',
    name: 'user-parent',
    redirect: { name: 'user-settings' },
    component: UserParent,
    meta: {
      app: true,
      context: 'user',
      title: 'User',
      versionCheck: true,
    },
    beforeEnter: [awaitUser, requireAuth],
    children: [
      {
        path: 'settings/',
        name: 'user-settings', // 'settings' -> 'user-settings'
        meta: {
          title: 'Settings',
        },
        component: () => import('../views/user/Settings.vue'),
      },
    ],
  },
]
