import { awaitUser, requireAdmin } from './middleware.js'

export default [
  //
  // Admin Pages
  // ^/admin
  //
  {
    path: '/admin/',
    name: 'admin-parent',
    redirect: { name: 'admin-users' },
    component: () => import('../views/admin/Parent.vue'),
    meta: {
      app: true,
      context: 'admin',
      title: 'Admin',
    },
    beforeEnter: [awaitUser, requireAdmin],
    children: [
      {
        path: 'users/',
        name: 'admin-users',
        meta: {
          title: 'Users',
        },
        component: () => import('../views/admin/Users.vue'),
      },
      {
        path: 'firestore/',
        name: 'admin-firestore',
        meta: {
          title: 'Firestore',
        },
        component: () => import('../views/admin/Firestore.vue'),
      },
      {
        path: 'test/',
        name: 'admin-test',
        meta: {
          title: 'Test',
        },
        component: () => import('../views/admin/Test.vue'),
      },
      {
        path: 'styleguide/',
        name: 'admin-styleguide',
        meta: {
          title: 'Styleguide',
        },
        component: () => import('../views/admin/Styleguide.vue'),
      },
      {
        path: 'timezones/',
        name: 'admin-timezones',
        meta: {
          title: 'timezones',
        },
        component: () => import('../views/admin/Timezones.vue'),
      },
      {
        path: 'temp/',
        name: 'admin-temp',
        meta: {
          title: 'Temp',
        },
        component: () => import('../views/admin/Temp.vue'),
      },
    ],
  },
]
