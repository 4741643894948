import * as GtagService from './GtagService.js'
import { getReferrers } from './referrers.js'
import axios from './axios.js'

let pathCache = null
export function trackPageview () {
  try {
    const path = _getFullPath()
    if (pathCache === path) return
    GtagService.pageview(path)
    axios.post('/aux/tp', { path })
    pathCache = path
  } catch (err) {
    console.error(err)
  }
}

export function trackUserEvent (event, meta = {}) {
  try {
    axios.post('/aux/tue', { event, meta })
  } catch (err) {
    console.error(err)
  }
}

export function trackEvent (action, payload = {}) {
  try {
    axios.post('/aux/te', {
      path: _getFullPath(),
      action: action,
      name: payload.name?.toLowerCase(),
      value: payload.value,
    })
  } catch (err) {
    console.error(err)
  }
}

export function trackFeature (name, value = undefined) {
  try {
    axios.post('/aux/tf', {
      path: _getFullPath(),
      name: name?.toLowerCase(),
      value,
    })
  } catch (err) {
    console.error(err)
  }
}

function _getFullPath () {
  const referrers = getReferrers()
  const url = new URL(window.location)
  if (referrers.length) url.searchParams.set('utm_campaign', referrers.join(','))
  return url.pathname + url.search
}
