import { useStagetimer } from '../store/stagetimer.js'
import { useUser } from '../store/user.js'
import { useClients, getStoredName, setStoredName } from '../store/clients.js'
import { useRoom } from '../store/room.js'
import { useTimeset } from '../store/timeset.js'
import { useTimers } from '../store/timers.js'
import { useMessages } from '../store/messages.js'
import eventBus from '../utils/eventBus.js'
import DelayQueueService from './DelayQueueService.js'
import TimeoutNotificationService from './TimeoutNotificationService.js'
import localStorage from '../../shared/localStorage.js'
import { v4 as uuidv4 } from 'uuid'
import mitt from 'mitt'

const verbosity1 = parseInt(localStorage.getItem('stagetimer:verbosity')) >= 1

let resolveConnected
const $private = {
  // Transports
  Transport: null,

  // Connection
  connected: new Promise(r => (resolveConnected = r)),
  emitter: mitt(),

  // Client
  clientId: uuidv4(),
  client: null,

  // Store
  stagetimer: null,
  user: null,
  clients: null,
  room: null,
  timeset: null,
  timers: null,
  messages: null,

  // Service Instances
  delayQueue: null,
  timeoutNotification: null,
}

const eventWhitelist = [
  'connect',
  'disconnect',
  'serverTime',
  'serverVersion',
  'client/rename',
  'client/identify',
  'client/forceReload',
  'client/kick',
  'clients',
]

export default class Socket {
  static init (Transport) {
    // Init Store
    $private.stagetimer = useStagetimer()
    $private.user = useUser()
    $private.clients = useClients()
    $private.room = useRoom()
    $private.timeset = useTimeset()
    $private.timers = useTimers()
    $private.messages = useMessages()

    // Init Delay FiFo Queue Service
    $private.delayQueue = new DelayQueueService(Socket.handleEvent, eventWhitelist)

    // Init Timeout Notification Service
    $private.timeoutNotification = new TimeoutNotificationService()

    // Init transports
    $private.Transports = Transport
    $private.Transports.init(Socket.listen)

    window.onbeforeunload = () => $private.timeoutNotification.disable()
  }

  static async connect (
    channelId,
    { roomPid, view, name = getStoredName(), delay = 0 } = {},
  ) {
    const start = performance.now()
    await $private.user.init
    if (verbosity1) console.info('[Socket] connecting')
    $private.client = {
      id: $private.clientId,
      roomPid,
      channelId,
      view,
      name,
      uid: $private.user.uid || null,
      thumb: $private.user.photoUrl || null,
      delay,
    }
    $private.clients.setId($private.clientId)
    await $private.Transports.connect(channelId, $private.client)
    resolveConnected()
    $private.timeoutNotification.enable()
    $private.timeoutNotification.resolve()
    if (verbosity1) console.info(`[Socket] connected (${(performance.now() - start).toFixed(3)} ms)`)
  }

  static async disconnect () {
    await $private.Transports.disconnect()
    $private.connected = new Promise(r => (resolveConnected = r))
    $private.timeoutNotification.disable()
    $private.timeoutNotification.remove()
    if (verbosity1) console.info('[Socket] disconnected')
  }

  static getClient () {
    return $private.client
  }

  static async rename (name) {
    if (!name || $private.client.name === name) return
    await $private.connected
    $private.client.name = name
    setStoredName(name)
    if (verbosity1) console.info('[Socket] rename', name)
    await $private.Transports.updateClient($private.client)
  }

  static async broadcast (event, payload) {
    if (verbosity1) console.info('[Socket] broadcast', { event, payload })
    await $private.Transports.broadcast(event, payload)
  }

  static listen (event, payload, t) {
    if (verbosity1) console.info('[Socket] listen event', { event, t })
    $private.delayQueue.push(event, payload, t, $private.timeset.delay)
  }

  static async handleEvent (event, payload, t) {
    if (verbosity1) console.info('[Socket] handle event', { event, payload })
    const forMe = payload?.clientId ? payload?.clientId === $private.clientId : true
    if (forMe) $private.emitter.emit(event, payload)
    switch (event) {
      // Connection
      case 'connect': return $private.timeoutNotification.resolve()
      case 'disconnect': return $private.timeoutNotification.trigger()
      // Stagetimer
      case 'serverTime': return $private.stagetimer.setServerTime(payload)
      case 'serverVersion': return $private.stagetimer.setServerVersion(payload)
      // Client (aka. myself)
      case 'client/rename': return forMe ? Socket.rename(payload.name) : null
      case 'client/identify': return forMe ? eventBus.$emit('client/identify') : null
      case 'client/forceReload': return forMe ? eventBus.$emit('client/forceReload') : null
      case 'client/kick': return forMe ? eventBus.$emit('client/kick') : null
      // Clients
      case 'clients': return $private.clients.set(payload)
      // Room
      case 'room/name': return $private.room.setName(payload, t)
      case 'room/settings': return $private.room.updateSettings(payload, t)
      case 'room/secrets': return $private.room.setSecrets(payload, t)
      case 'room/team': return $private.room.setTeam(payload, t)
      case 'room/plan': return $private.room.setPlan(payload, t)
      // Timeset
      case 'timeset': return $private.timeset.set(payload, t)
      // Timers
      case 'timers/items': return $private.timers.setItems(payload, t)
      case 'timers/updateOne': return $private.timers.setOne(payload, t)
      case 'timers/removeOne': return $private.timers.removeOne(payload, t)
      case 'timers/flash': return $private.timers.flash(payload, t)
      // Messages
      case 'messages/items': return $private.messages.setAll(payload /* message[] */, t)
      case 'messages/updateOne': return $private.messages.setOne(payload /* message */, t)
      case 'messages/removeOne': return $private.messages.removeOne(payload /* messageId */, t)
      case 'messages/flash': return $private.messages.flash(payload /* { count: number } */)
      case 'messages/show': return $private.messages.show(payload /* message */, t)
      case 'messages/hide': return $private.messages.hide(payload /* message */, t)
    }
  }
}

/**
 * Expose an event emitter for socket events
 */
Socket.$on = (...args) => $private.emitter.on(...args)
Socket.$off = (...args) => $private.emitter.off(...args)
