/**
 * Delay FiFo Queue
 */
export default class DelayQueueService {
  /**
   * @param  {Function} callback
   * @param  {Array}    whitelist ['event' string that are excluded from delays]
   */
  constructor (callback, whitelist = []) {
    this.callback = callback
    this.whitelist = whitelist
  }

  push (event, payload, t, delay = 0) {
    if (delay === 0 || this.whitelist.includes(event)) return this.callback(event, payload, t)
    return setTimeout(() => this.callback(event, payload, t), delay)
  }
}
