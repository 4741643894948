<template>
  <div
    class="relative min-h-screen"
    :class="backgroundClass"
    :data-route="route.name"
  >
    <AsyncRouteProgressBar />
    <router-view />
    <div
      v-if="loading && loadingOverlay"
      class="absolute left-0 top-0 right-0 h-screen z-50 flex items-center justify-center"
    >
      <div class="flex items-center p-10 bg-black/40 backdrop-blur rounded-xl shadow-xl text-white">
        <Spinner class="mr-6" light color="teal" />
        <div class="text-2xl font-semibold">
          Loading...
        </div>
      </div>
    </div>
    <NotificationContainer />
    <ConsentBanner
      v-if="routeReady"
      :config="config"
      :hide="route.meta.hideConsentBanner"
    />
  </div>
</template>

<script setup>
import AsyncRouteProgressBar from './components/shared/AsyncRouteProgressBar.vue'
import NotificationContainer from './components/shared/NotificationContainer.vue'
import ConsentBanner from '../shared/components/ConsentBanner.vue'
import Spinner from '../shared/components/Spinner.vue'
import eventBus from './utils/eventBus.js'
import requestIdleCallback from './utils/requestIdleCallback.js'
import { useHead } from '@vueuse/head'
import { useRouter, useRoute } from 'vue-router'
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import config from '../shared/configImporter.js'

const router = useRouter()
const route = useRoute()
const loading = ref(true)

/**
 * Await route object
 */
const routeReady = ref(false)
onMounted(async () => {
  await router.isReady
  requestIdleCallback(() => (routeReady.value = true))
})

const loadingOverlay = computed(() => route.meta?.loadingOverlay !== false)
const backgroundClass = computed(() => {
  switch (route.meta?.background) {
    case 'transparent':
      return 'bg-transparent'
    case 'dark':
      return 'bg-neutral-900 text-white'
    case 'light':
    default:
      return 'bg-white text-neutral-900'
  }
})

const title = computed(() => route.meta.title || 'Remote-controlled Countdown Timer')
const description = computed(() => route.meta.description || 'Show a fullscreen timer to your presenter while you control it from another device. Runs in the browser. Basic version is free. Works on all screen sizes.')
const href = computed(() => route.fullPath && window?.location?.href)

useHead ({
  titleTemplate: titleChunk => titleChunk
    ? `${titleChunk} | stagetimer.io`
    : 'Remote-controlled Countdown Timer | stagetimer.io',
  title: title,
  meta: [
    { name: 'description', content: description },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { property: 'og:url', content: href },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
  ],
  link: { rel: 'canonical', href: href },
})

// OBSOLETE?
// watch: {
//   /**
//    * Add overflow="hidden" if modal is open
//    */
//   modalOpen (value) {
//     if (!document?.body?.style) return
//     document.body.style.overflow = value ? 'hidden' : ''
//   },
// },

const loadingStart = () => (loading.value = true)
const loadingStop = () => (loading.value = false)
onMounted(() => {
  eventBus.$on('asyncRouteLoadingStart', loadingStart)
  eventBus.$on('asyncRouteLoadingStop', loadingStop)
})
onBeforeUnmount(() => {
  eventBus.$off('asyncRouteLoadingStart', loadingStart)
  eventBus.$off('asyncRouteLoadingStop', loadingStop)
})
</script>
