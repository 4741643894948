import { defineStore } from 'pinia'
import { useStagetimer } from './stagetimer.js'
import { useRoom } from './room.js'
import { useTimers } from './timers.js'
import createMoment from '../utils/createMoment.js'
import * as OOOService from '../utils/OutOfOrderService.js'
import _clamp from 'lodash/clamp'
import _ceil from 'lodash/ceil'

export const useTimeset = defineStore('timeset', {
  state: () => ({
    timeset: {
      timerId: null,
      running: false,
      deadline: null,
      kickoff: null,
      lastStop: null,
    },
    runner: 0,
    delay: 0, // delay in ms
  }),
  getters: {
    get: state => state.timeset,
    running: state => Boolean(state.timeset?.running),
    timerId: state => state.timeset?.timerId || null,
    now: state => {
      const stagetimer = useStagetimer()
      // force recompute
      return state.runner && stagetimer.getNow() || Date.now()
    },
    total: state => state.timeset.deadline - state.timeset.kickoff,
    moment: state => {
      const timers = useTimers()
      const timer = timers.active
      const room = useRoom()
      const behavior = room.settings.timer?.behavior
      const todFormat = room.settings.timer?.todFormat
      const countdownFormat = room.settings.timer?.countdownFormat
      return createMoment(state.timeset, timer, state.now, behavior, todFormat, countdownFormat)
    },
    percent: state => _ceil(_clamp((1 - state.moment.left / state.moment.total) * 100, 0, 100), 1),
  },
  actions: {
    set (timeset, t) {
      if (!OOOService.check('timeset', t)) return
      Object.assign(this.timeset, timesetAddDelay(timeset, this.delay))
    },
    incrementRunner () {
      this.runner++
    },
    setDelay (ms = 0) {
      this.delay = Number(ms)
    },
  },
})

function timesetAddDelay (timeset, delay = 0) {
  return {
    ...timeset,
    deadline: timeset.deadline + delay,
    kickoff: timeset.kickoff + delay,
    lastStop: timeset.lastStop + delay,
  }
}
