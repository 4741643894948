import { defineStore } from 'pinia'
import { electronConstants } from '@stagetimerio/shared'
import axios from '../../shared/axios.js'
import _keyBy from 'lodash/keyBy'
import _set from 'lodash/set'

let resolveInitPromise = undefined

export const useUser = defineStore('user', {
  state: () => ({
    initPromise: new Promise(r => resolveInitPromise = r),
    user: null,
    teamsMap: {},
    loading: true,
    error: null,
    justPurchased: false,
  }),
  getters: {
    init: state => state.initPromise,
    get: state => state.user,
    uid: state => state.user?.uid,
    admin: state => Boolean(state.user?.customClaims?.admin),
    photoUrl: state => state.user?.photoURL || 'https://stagetimer.io/assets/default-avatar.png',
    email: state => state.user?.email,
    displayName: state => state.user?.displayName || state.user?.email,
    anonymous: state => state.user?.isAnonymous,
    authenticated: state => state.user && !state.user.isAnonymous,
    creationTime: state => new Date(state.user?.creationTime),
    teams: state => _sortTeams(Object.values(state.teamsMap), state.uid),
    isTeamMember: state => team => team && (state.uid in team.members),
    getTeamRole: state => team => team && team.members[state.uid]?.role,
    canAccessTeam: state => (team, roles = true) => {
      if (state.admin) return true
      if (roles === true) return state.isTeamMember(team)
      if (typeof roles === 'string') return state.getTeamRole(team) === roles
      if (Array.isArray(roles)) return roles.includes(state.getTeamRole(team))
      return false
    },
  },
  actions: {
    /**
     * Resets the store state, particularly the initPromise, to its initial state.
     */
    reset () {
      this.initPromise = new Promise(r => resolveInitPromise = r)
    },

    /**
     * Initializes user data upon Electron app initiation.
     * This is typically called with the user ID from the Electron constants.
     */
    async onElectronInit () {
      await this.loadUser(electronConstants.uid)
    },

    /**
     * Handles user authentication updates from Firebase.
     * If the user is logged in, it attempts to load user data.
     * If the user is null (not logged in), it resets the store state.
     * @param {Object|null} user - The user object from Firebase auth, or null if not logged in.
     */
    async onFirebaseAuth (user) {
      // Handle the case where user is not logged in
      if (!user) return this.unsetUser()

      await this.loadUser(user.uid)
    },

    /**
     * Loads the user and their teams' data from the server.
     * If the data is not immediately available, it retries after a delay.
     * @param {string} uid - The user ID for which to load data.
     * @returns {Object|null} The loaded user object, or null if loading fails.
     * @throws {Error} Throws an error if unable to fetch user data after retries.
     */
    async loadUser (uid) {
      this.error = null
      this.loading = true

      let retryCount = 0
      const maxRetries = 5 // Maximum number of retries
      const baseDelay = 1000 // Base delay in milliseconds (1 second)

      const fetchData = async () => {
        try {
          const [{ data: user }, { data: teams }] = await Promise.all([
            axios.get(`/users/${uid}`),
            axios.get(`/users/${uid}/teams`),
          ])

          if (!user || !teams.length) {
            throw new Error('User or teams data not yet available')
          }

          this.user = user
          this.teamsMap = _keyBy(teams, 'id')
          return user
        } catch (err) {
          if (err?.response?.status === 404 && retryCount < maxRetries) {
            console.info(`[store/user] Retrying to fetch user data... Attempt ${retryCount + 1}`)
            const delay = baseDelay * Math.pow(2, retryCount)
            await new Promise(resolve => setTimeout(resolve, delay)) // exponential backoff
            retryCount++
            return fetchData()
          } else {
            this.error = err
            throw err
          }
        }
      }

      await fetchData().finally(() => {
        this.loading = false
        resolveInitPromise?.(this.user)
      })
    },

    unsetUser () {
      this.user = null
      this.teamsMap = {}
      this.loading = false
      resolveInitPromise?.(this.user)
    },

    /**
     * Updates the specified team's data in the store.
     * @param {string} teamId - The ID of the team to update.
     * @param {Object} patch - An object containing the updates to apply to the team.
     */
    updateTeam (teamId, patch) {
      _set(this.teamsMap, teamId, patch)
    },
  },
})

/**
 * Make sure the user's primary team comes first
 * @param  {object[]} items
 * @param  {string} uid
 * @return {object[]}
 */
function _sortTeams (items, uid) {
  return items.sort((a, b) => (a.id === uid) ? -1 : ((b.id === uid) ? 1 : 0))
}
