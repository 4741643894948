<template>
  <div class="flex flex-col justify-end fixed bottom-12 left-12 w-0 z-[101]">
    <transition-group name="toast">
      <NotificationItem
        v-for="item in filteredNotifications"
        :key="item.id"
        :item="item"
        class="toast-item mt-3"
        @dismiss="notifications.remove(item.id)"
      />
    </transition-group>
  </div>
</template>

<script setup>
import NotificationItem from './NotificationItem.vue'
import { useNotifications, notificationTypes } from '../../store/notifications.js'
import { useRoute } from 'vue-router'
import { ref, computed, onMounted } from 'vue'

const notifications = useNotifications()
const route = useRoute()

const unloading = ref(false)
const onUnload = () => unloading.value = true
onMounted(() => document.addEventListener('onbeforeunload', onUnload))

const typeWhitelist = computed(() => route.meta?.notifications || Object.values(notificationTypes))
const filteredNotifications = computed(() => {
  if (unloading.value) return []
  return notifications.all.filter(({ type }) => typeWhitelist.value.includes(type))
})

// const showNotifications = computed(() => {
//   if (route.meta?.notifications === false) return false
//   return true
// })
</script>

<style scoped>
.z-100 {
  z-index: 100;
}
.toast-item {
  transition: all .5s ease;
}
.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateX(-1rem);
}
.toast-leave-active {
  position: absolute;
  /*bottom: 0;*/
}

</style>
