import validateRoomPid from '../utils/validateRoomPid.js'
import FirebaseSingleton from '../../shared/firebase.js'
import { useUser } from '../store/user.js'

export async function awaitUser (to, from, next) {
  const user = useUser()
  await user.init
  next()
}

export async function requireAuth (to, from, next) {
  const user = await FirebaseSingleton.authenticate()
  if (user) return next()
  next({ name: 'login', query: { next: to.fullPath } })
}

export async function requireAdmin () {
  const user = useUser()
  await user.init
  if (user.admin) return true
  return { name: 'error', query: { message: 'Authentication required' } }
}

export async function validatePid (to, from, next) {
  // ensure that roomPid is always uppercase
  const roomPid = String(to.params.roomPid || '').toUpperCase()
  if (to.params.roomPid !== roomPid) return next({ ...to, params: { roomPid } })

  // validate roomPid
  if (validateRoomPid(roomPid)) return next()
  return next({ name: 'roomInvalid', query: { roomPid: roomPid } })
}
