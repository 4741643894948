/**
 * Wrapper around localStorage so the app don't crashes if access is denied
 *
 * Error: Uncaught (in promise) DOMException: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.
 */

export default {
  available () {
    try {
      window.localStorage.getItem('test')
      return true
    } catch {
      // Assume local storage is not available or access is denied
      return false
    }
  },
  getItem (key) {
    try {
      return window.localStorage.getItem(key)
    } catch {
      return undefined
    }
  },
  setItem (key, value) {
    try {
      window.localStorage.setItem(key, value)
    } catch {
      return undefined
    }
  },
}
