<template>
  <ModalDefault
    :open="props.open"
    title="Menu"
    modal-class="max-w-xl text-left font-light text-neutral-900"
    align-v="top"
    closable
    hide-footer
    @update:open="emit('update:open', $event)"
  >
    <component
      :is="item.href ? 'a' : 'p'"
      v-for="(item, i) in props.items"
      :key="`mobile-menu-item-${i + 1}`"
      class="block rounded h-10 leading-10"
      :class="{
        'text-teal-500 font-medium text-xs mt-10 first:mt-0': Boolean(item.heading),
      }"
      :href="item.href"
    >
      {{ item.label || item.heading }}
    </component>

    <hr
      v-if="$slots.default"
      class="my-3"
    >

    <slot />
  </ModalDefault>
</template>

<script setup>
import ModalDefault from './ModalDefault.DEPRECATED.vue'

const emit = defineEmits(['update:open'])
const props = defineProps({
  items: { type: Array, default: () => [] },
  open: Boolean,
})
</script>
