import { useNotifications, INFINITE } from '../store/notifications.js'

/**
 * Handle Socket Timeouts
 */
export default class TimeoutNotificationService {
  constructor () {
    this.store = useNotifications()
    this.notificationId = null
    this.enabled = true
  }

  // Enable the showing of the notifications
  enable () {
    this.enabled = true
  }

  // Disable the showing of the notifications
  disable () {
    this.enabled = false
  }

  // Trigger the timeout notification
  trigger () {
    if (!this.enabled || this.notificationId) return
    this.notificationId = this.store.error({
      title: 'Lost connection to server',
      text: 'Attempting to reconnect',
      spinner: true,
      duration: INFINITE,
    })
  }

  // Remove the timeout notification and show a message that the connection is restored
  resolve () {
    if (this.notificationId) {
      this.remove()
      this.store.success({
        title: 'Restored connection to server',
      })
    }
  }

  // Remove the timeout notification
  remove () {
    if (this.notificationId) {
      this.store.remove(this.notificationId)
      this.notificationId = null
    }
  }
}
