import { parse } from 'date-fns/parse'
import { format } from 'date-fns/format'
import { formatInTimeZone } from 'date-fns-tz/formatInTimeZone'
import { parseISO } from 'date-fns/parseISO'
import { formatDistance } from 'date-fns/formatDistance'
import { addMilliseconds } from 'date-fns/addMilliseconds'
import { todFormats, timerTriggers, timerTypes } from '@stagetimerio/shared'
import {
  millisecondsToHms,
  hmsToMilliseconds,
  isValidDate,
  parseDate,
  parseDateAsToday,
  applyDate,
  millisecondsToDhms,
  formatTimezone,
  getToday,
  getTomorrow,
  isSameDay,
  isValidTimezone,
} from '@stagetimerio/timeutils'
import singularPlural from '../../shared/utils/singularPlural.js'

const zero = new Date().setHours(0, 0, 0, 0)

export function alterMinutes (ms = 0, minutes = 0) {
  const hms = millisecondsToHms(ms)
  hms.minutes = minutes
  return hmsToMilliseconds(hms)
}

export function alterSeconds (ms = 0, seconds = 0) {
  const hms = millisecondsToHms(ms)
  hms.seconds = seconds
  return hmsToMilliseconds(hms)
}

export function addHmsToDate (date, hms) {
  const parsedDate = parseDate(date)
  return parsedDate ? addMilliseconds(parsedDate, hmsToMilliseconds(hms)) : null
}

export function formatTimeOfDay (date, {
  todFormat = todFormats.AUTO,
  timezone = null,
  omitSeconds = false,
  secondsOptional = false,
  hour12 = undefined,
} = {}) {
  const parsedDate = parseDate(date)
  if (!parsedDate) return ''
  if (todFormat === todFormats.AUTO) {
    const options = { timeStyle: 'medium' }
    const hasSeconds = parsedDate.getSeconds() !== 0
    if (timezone) options.timeZone = timezone
    if ((secondsOptional && !hasSeconds) || omitSeconds) options.timeStyle = 'short'
    if (hour12 !== undefined) options.hour12 = hour12
    const language = !navigator.language || navigator.language === 'C' ? 'en-US' : navigator.language
    try {
      return new Intl.DateTimeFormat(language, options).format(parsedDate)
    } catch {
      options.timeZone = 'UTC'
      return new Intl.DateTimeFormat(language, options).format(parsedDate)
    }
  } else {
    if (timezone) return formatInTimeZone(parsedDate, timezone, todFormat)
    else return format(parsedDate, todFormat)
  }
}

/**
 * Format calendar date using the browser language
 * @param  {Date|String} date
 * @param  {string} [timezone]
 * @return {string}
 */
export function formatCalendarDate (date, timezone = undefined) {
  const parsedDate = parseDate(date)
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  if (timezone) options.timeZone = timezone
  return new Intl.DateTimeFormat(navigator.language, options).format(parsedDate)
}

/**
 * Formats a duration in milliseconds into a readable string.
 *
 * @param {number} milliseconds - The duration in milliseconds to format.
 * @param {Object} options - Formatting options.
 * @param {boolean} options.includeH - Whether to include hours in the output.
 * @param {boolean} options.includeS - Whether to include seconds in the output.
 * @param {boolean} options.includeMs - Whether to include milliseconds in the output.
 * @param {boolean} options.includePrefix - Whether to include a '-' prefix for negative durations.
 * @param {string} options.customFormat - A custom format string to use instead of the default format.
 *
 * @returns {string} The formatted duration string.
 */
export function formatDuration (milliseconds = 0, {
  includeH = true,
  includeS = true,
  includeMs = false,
  includePrefix = true,
  customFormat = null,
} = {}) {
  // Return an empty string if milliseconds is not a number or is NaN
  if (typeof milliseconds !== 'number' || isNaN(milliseconds)) return ''
  const withMs = includeMs
  const withSec = includeS || Math.abs(milliseconds) < 3600000
  const withHrs = includeH || Math.abs(milliseconds) >= 3600000

  // Determine the prefix of the output string.
  const prefix = includePrefix ? (milliseconds < 0 ? '-' : '') : ''

  // Determine the format string based on the options.
  let formatStr = 'm'
  if (withMs) formatStr = 'm:ss.S'
  else if (withSec) formatStr = 'm:ss'
  if (withHrs) formatStr = 'm' + formatStr

  // Format the duration into the output string
  let output = format(zero + Math.abs(milliseconds), customFormat || formatStr)

  // Add hours to the output if necessary
  if (withHrs) {
    output = Math.floor(Math.abs(milliseconds) / 3600000) + ':' + output
  }

  // Return the formatted duration string with the prefix.
  return prefix + output
}

export function formatDurationInWords (milliseconds, { exact = false, space = ' ' } = {}) {
  if (!exact) return formatDistance(zero, zero + Math.abs(milliseconds))
  const dhms = millisecondsToDhms(milliseconds)
  let parts = []
  if (dhms.days) parts.push(singularPlural(dhms.days, 'day', 'days', space))
  if (dhms.hours) parts.push(singularPlural(dhms.hours, 'hr', 'hrs', space))
  if (dhms.minutes) parts.push(singularPlural(dhms.minutes, 'min', 'mins', space))
  if (dhms.seconds) parts.push(singularPlural(dhms.seconds, 'sec', 'secs', space))
  return parts.join(' ')
}

export function formatHmsToDuration (timer, { includeH = true, includeS = true, includeMs = false } = {}) {
  return formatDuration(hmsToMilliseconds(timer), { includeH, includeS, includeMs })
}

export function detectBrowserTodFormat () {
  const formattedTime = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).format(0)
  return /AM|PM/i.test(formattedTime) ? todFormats.DEFAULT_H12 : todFormats.DEFAULT_H24
}

export function isBrowserH12 () {
  return detectBrowserTodFormat() === todFormats.DEFAULT_H12
}

export function roundUpToMin (date, min = 10) {
  const roundUpTo = (date, roundTo) => new Date(Math.ceil(date / roundTo) * roundTo)
  return roundUpTo(date, 1000 * 60 * min)
}

/**
 * Understand time formats like `8:30 PM`, `20:30`, `20:30:00` or `2022-01-01 20:30`
 * @value {string} time - time to parse
 * @value {boolean} [options.recursive] - `recursive: true` is for internal use only
 * @value {Date} [options.ref] - The reference date to use if the input string doesn't contain date information
 * @return {Date}
 */
export function parseUserInputTime (input, { recursive = false, ref = new Date() } = {}) {
  const tz = getBrowserTimezone()
  const sanitize = (date) => applyDate(date, ref, tz)

  if (!input) return null
  const today = new Date(new Date().setHours(0, 0, 0, 0))
  let str = input
  let date = null

  // return early if there's no number in it
  if (!str.replace(/[^\d]/g, '').length) return null

  // Try parsing it as ISO string
  date = parseISO(str)
  if (isValidDate(date)) return sanitize(date)

  // sanitize str
  str = String(str).toLowerCase()
  const regex = /[\s\dhmsap:\-\./]+/gmi // eslint-disable-line no-useless-escape
  str = (str.match(regex)?.[0] || '').trim()

  // Try parsing AM/PM
  if (/am|pm/i.test(str)) {
    const regex = /(\d{1,2}:?\d{0,2}:?\d{0,2})\s?(am|pm)/i
    const matches = str.match(regex)
    const testStr = matches[1] + ' ' + matches[2]
    date = parse(testStr, 'h a', today)
    if (isValidDate(date)) return sanitize(date)
    date = parse(testStr, 'h:m a', today)
    if (isValidDate(date)) return sanitize(date)
    date = parse(testStr, 'h:m:s a', today)
    if (isValidDate(date)) return sanitize(date)
  }

  // Try parsing 0h 0m 0s variants
  if (/[hms]/i.test(str)) {
    const { groups } = /(?<h>\d{1,2}h)?\s*(?<m>\d{1,2}m)?\s*(?<s>\d{1,2}s)?/gi.exec(str)
    if (groups.h || groups.m || groups.s) {
      return sanitize(new Date(today.setHours(
        Number(groups.h?.replace('h', '')) || 0,
        Number(groups.m?.replace('m', '')) || 0,
        Number(groups.s?.replace('s', '')) || 0,
      )))
    }
  }

  // Try parsing hh:mm:ss variants
  if (str.includes(':')) {
    const regex = /\d{1,2}:?\d{0,2}:\d{0,2}/gi
    const match = str.match(regex)?.[0]
    date = parse(match, 'HH:mm:ss', today)
    if (isValidDate(date)) return sanitize(date)
    date = parse(match, 'HH:mm', today)
    if (isValidDate(date)) return sanitize(date)
  }

  // If there's no date, replace potentially funny separators and try parsing it
  const isOnlyTime = /^[^\d\n]*\d{1,2}[^\s]?\d{0,2}[^\s]?\d{0,2}\s?[^\d\n]*$/g.test(str)
  if (isOnlyTime && !recursive) {
    const newStr = str.replace(/(\d).(\d)/g, '$1:$2')
    date = parseUserInputTime(newStr, { recursive: true })
    if (isValidDate(date)) return sanitize(date)
  }

  // Lastly, just try parsing as is with Date.parse()
  date = Date.parse(str)
  if (!isNaN(date)) return sanitize(new Date(date))

  date = Date.parse(format(new Date(), 'yyyy-MM-dd') + ' ' + str)
  if (!isNaN(date)) return sanitize(new Date(date))

  return null
}

export function parseUserInputDuration (input) {
  if (!input) return null
  const hms = { h: 0, m: 0, s: 0 }

  // sanitize date str
  let str = String(input).toLowerCase()
  const regex = /^[\s\dhms:']+/i
  str = (str.match(regex)?.[0] || '').trim()
  if (!str) return null

  // return early if there's no number in it
  if (!str.replace(/[^\d]/g, '').length) return null

  if (str.includes(':')) {
    let parts = str.split(':')
    if (parts.length === 3) {
      hms.h = Number(parts[0]) || 0
      hms.m = Number(parts[1]) || 0
      hms.s = Number(parts[2]) || 0
    } else if (parts.length === 2) {
      hms.m = Number(parts[0]) || 0
      hms.s = Number(parts[1]) || 0
    }
  } else if (/h|m|s/i.test(str)) {
    const { groups: { h, m, s } } = /(?<h>\d{1,2}h)?\s*(?<m>\d{1,2}m)?\s*(?<s>\d{1,2}s)?/gi.exec(str)
    hms.h = Number(h?.replace('h', '')) || 0
    hms.m = Number(m?.replace('m', '')) || 0
    hms.s = Number(s?.replace('s', '')) || 0
  } else if (str.includes('\'')) {
    hms.m = Number(str.replace('\'', ''))
  } else {
    hms.m = Number(str)
  }

  return hmsToMilliseconds({
    hours: hms.h,
    minutes: hms.m,
    seconds: hms.s,
  })
}

/**
 * Generates descriptive text about the start time of a timer based on provided parameters.
 *
 * @param {Object} params - The parameters for formatting the start time.
 * @param {string} params.trigger - The trigger type for the timer (manual, linked, or scheduled).
 * @param {string} [params.startTime] - The ISO string or Date object for the start time.
 * @param {boolean} params.startDate - Specifies if the date part of startTime should be considered.
 * @param {string} [params.fallbackDate] - A fallback date to use if startTime is not provided.
 * @param {string} params.timezone - The timezone in which to interpret the start time.
 * @param {string} [params.todFormat] - The format of the time of day, default is undefined.
 *
 * @returns {Object} - An object containing formatted strings describing the start time and date,
 *                     including the timezone and trigger condition. This includes the complete descriptive text,
 *                     and individual components of this text (time, date, timezone, and trigger).
 */
export function formatTimerStartInWords ({
  trigger,
  startTime,
  startDate,
  fallbackDate,
  timezone,
  todFormat = undefined,
}) {
  const time = startTime
    ? startDate
      ? parseDate(startTime)
      : parseDateAsToday(startTime, { after: fallbackDate || undefined, timezone })
    : fallbackDate

  const hour12 = todFormat === todFormats.AUTO ? isBrowserH12() : todFormats.isH12(todFormat)
  const fTime = time
    ? formatTimeOfDay(time, { timezone, hour12, secondsOptional: true })
    : ''

  const fDate = (() => {
    if (!time) return ''
    if (isSameDay(time, getToday(timezone), timezone)) return 'Today'
    if (isSameDay(time, getTomorrow(timezone), timezone)) return 'Tomorrow'
    return formatCalendarDate(time, timezone)
  })()

  const fTimezone = formatTimezone(timezone, 'abbr', time || undefined)

  const fTrigger = {
    [timerTriggers.MANUAL]: 'Triggered manually.',
    [timerTriggers.LINKED]: 'Triggered when previous timer reaches 0:00.',
    [timerTriggers.SCHEDULED]: 'Triggered automatically.',
  }[trigger]

  let verb = 'Implicit'
  if (trigger === timerTriggers.SCHEDULED) verb = 'Scheduled'
  else if (startTime) verb = 'Planned'

  const text = []
  if (fTime) text.push(`${verb} start at ${fTime} ${fDate}${fTimezone ? ' (' + fTimezone + ')' : ''}.`)
  else text.push('No start time given.')
  text.push(fTrigger)

  return {
    text: text.join(' ').trim(),
    time: fTime,
    date: fDate,
    timezone: fTimezone,
    trigger: fTrigger,
  }
}

/**
 * Formats the end details of a timer into human-readable words, including end time, duration, and timezone.
 *
 * @param {Object} params - Parameters for formatting timer end details.
 * @param {string} params.type - Timer type, affecting duration calculation and text (e.g., 'DURATION', 'FINISH_TIME').
 * @param {number} params.hours - Duration hours, used if type is 'DURATION'.
 * @param {number} params.minutes - Duration minutes, used if type is 'DURATION'.
 * @param {number} params.seconds - Duration seconds, used if type is 'DURATION'.
 * @param {string} params.finishTime - Specific end time, used if type is 'FINISH_TIME'.
 * @param {boolean} params.finishDate - Specifies if the date part of finishTime should be considered.
 * @param {string} [params.fallbackDate] - A fallback date to use if finishTime is not provided.
 * @param {string} params.timezone - Timezone ID for formatting time-related texts.
 * @param {string} [params.todFormat] - Optional format for the time of day.
 * @param {string} [params.space] - Optional different whitespace character inside time notations.
 *
 * @returns {Object} Formatted end details including textual description and individual time components.
 */
export function formatTimerEndInWords ({
  type,
  hours,
  minutes,
  seconds,
  finishTime,
  finishDate,
  fallbackDate,
  timezone,
  todFormat = undefined,
  space = ' ',
}) {
  const time = type === timerTypes.FINISH_TIME && finishTime
    ? finishDate
      ? parseDate(finishTime)
      : parseDateAsToday(finishTime, { after: fallbackDate || undefined, timezone })
    : fallbackDate

  const hour12 = todFormat === todFormats.AUTO ? isBrowserH12() : todFormats.isH12(todFormat)
  const fTime = time
    ? formatTimeOfDay(time, { timezone, hour12, secondsOptional: true })
    : ''

  const fDate = (() => {
    if (!time) return ''
    if (isSameDay(time, getToday(timezone), timezone)) return 'Today'
    if (isSameDay(time, getTomorrow(timezone), timezone)) return 'Tomorrow'
    return formatCalendarDate(time, timezone)
  })()

  const fTimezone = formatTimezone(timezone, 'abbr', time || undefined)

  const duration = hmsToMilliseconds({ hours, minutes, seconds })
  const fDuration = formatDurationInWords(duration, { exact: true, space })

  const text = []
  if (fTime) text.push(`Ends at ${fTime} ${fDate}${fTimezone ? ' (' + fTimezone + ')' : ''}.`)
  if (type === timerTypes.DURATION) text.push(`Counting down from ${fDuration}.`)
  if (type === timerTypes.FINISH_TIME) text.push('Duration is calculated at start.')

  return {
    text: text.join(' ').trim(),
    time: fTime,
    date: fDate,
    timezone: fTimezone,
    duration: fDuration,
  }
}

export function getBrowserTimezone () {
  try {
    const browserTz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone
    if (isValidTimezone(browserTz)) return browserTz
  } catch {
    return null
  }
  return null
}

export default {
  alterMinutes,
  alterSeconds,
  addHmsToDate,
  formatTimeOfDay,
  formatCalendarDate,
  formatHmsToDuration,
  detectBrowserTodFormat,
  isBrowserH12,
  roundUpToMin,
  parseUserInputTime,
  parseUserInputDuration,
  formatTimerStartInWords,
  formatTimerEndInWords,
  getBrowserTimezone,
}
