<!-- eslint-disable -->
<template>
  <svg viewBox="0 0 450 450" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g class="animate-float delay-1 filter drop-shadow">
      <circle cx="89" cy="329" r="76" fill="#F59E0B"/>
    </g>
    <g class="animate-float delay-2 filter drop-shadow">
      <circle cx="368" cy="98" r="63" fill="#EF4444"/>
    </g>
    <g class="animate-float filter drop-shadow-lg">
      <rect x="367.938" y="67.0002" width="266" height="354" rx="5" transform="rotate(75 367.938 67.0002)" fill="#292524"/>
      <text transform="translate(83.3921 210.537) rotate(-15)" fill="white" xml:space="preserve" style="white-space: pre" font-family="IBM Plex Mono" font-size="90" font-weight="600" letter-spacing="0em">
        <tspan x="0.0878906" y="92.25">{{ timeFormatted }}</tspan>
      </text>
      <g opacity="0.2">
        <path d="M88.6342 392.376L430.572 300.754L435.49 319.107C436.204 321.774 434.621 324.516 431.954 325.231L99.6755 414.264C97.0082 414.979 94.2665 413.396 93.5518 410.729L88.6342 392.376Z" fill="#22C55D"/>
        <path d="M350.4 322.236L430.572 300.754L435.489 319.107C436.204 321.774 434.621 324.516 431.954 325.231L356.612 345.419L350.4 322.236Z" fill="#F59E0B"/>
        <path d="M413.185 305.413L430.572 300.754L435.49 319.107C436.204 321.774 434.621 324.516 431.954 325.231L419.397 328.595L413.185 305.413Z" fill="#EF4444"/>
      </g>
      <mask id="mask0" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="88" y="300" width="349" height="116">
        <rect
          :x="88.6342 + (354 * (1 - progress))"
          y="392.376"
          width="354"
          height="24"
          transform="rotate(-15 88.6342 392.376)"
          fill="#000"
        />
      </mask>
      <g mask="url(#mask0)">
        <path d="M88.6342 392.376L430.572 300.754L435.49 319.107C436.204 321.774 434.621 324.516 431.954 325.231L99.6755 414.264C97.0082 414.979 94.2665 413.396 93.5518 410.729L88.6342 392.376Z" fill="#22C55D"/>
        <path d="M350.4 322.236L430.572 300.754L435.489 319.107C436.204 321.774 434.621 324.516 431.954 325.231L356.612 345.419L350.4 322.236Z" fill="#F59E0B"/>
        <path d="M413.185 305.413L430.572 300.754L435.49 319.107C436.204 321.774 434.621 324.516 431.954 325.231L419.397 328.595L413.185 305.413Z" fill="#EF4444"/>
      </g>
    </g>
  </svg>
</template>

<script>
import { format } from 'date-fns/format'

export default {
  data () {
    return {
      runner: 0,
      interval: null,
      time: 1200000,
    }
  },
  computed: {
    timeFormatted () {
      return format(this.time, 'mm:ss')
    },
    progress () {
      return this.time / 1200000
    },
  },
  mounted () {
    this.startTimer()
  },
  methods: {
    startTimer () {
      this.interval = setInterval(() => {
        this.time -= 100
        if (this.time <= 0) clearInterval(this.interval)
      }, 100)
    },
  },
}
</script>

<style scoped>
.animate-float {
  animation: float 6s infinite;
}
.delay-1 {
  animation: float 5s infinite;
}
.delay-2 {
  animation: float 4s infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
    animation-timing-function: ease-in-out;
  }
  50% {
    transform: translateY(-5%);
    animation-timing-function: ease-in-out;
  }
}
</style>
