/**
 * Formats a word in either its singular or plural form based on the provided quantity.
 * Automatically generates the singular or plural form if not provided.
 *
 * @param {number} n - The quantity to determine the form. If -1, it is treated as 'Unlimited'.
 * @param {string} [singular] - The singular form of the word. If empty, it is derived from the plural form.
 * @param {string} [plural] - The plural form of the word. If empty, it is derived from the singular form.
 * @param {string} [space] - The space character, defaults to ' '
 * @return {string} - The quantity followed by the word in its appropriate form.
 */
export default function singularPlural (n, singular = '', plural  = '', space = ' ') {
  if (!singular) singular = plural.replace(/s$/, '')
  if (!plural) plural = singular + 's'
  const quantity = n === -1 ? 'Unlimited' : n
  return quantity + space + (parseInt(n) === 1 ? singular : plural)
}
