<template>
  <div
    ref="containerRef"
    class="dropdown inline-block relative"
    :class="{ hover }"
    @mouseover="mouseover"
    @mouseleave="mouseleave"
    @focusin="mouseover"
    @focusout="mouseleave"
  >
    <slot
      name="button"
      :toggle="toggle"
      :show="show"
    />

    <transition name="fade">
      <div
        v-if="open"
        class="absolute top-full z-50 rounded border-neutral-600 p-1 shadow whitespace-nowrap mt-1"
        :class="[dropdownClass, {
          'bg-white text-neutral-900': !dark,
          'bg-neutral-800 border text-white': dark,
          'left-0': align === 'left',
          'left-1/2 -translate-x-1/2': align === 'center',
          'right-0': align === 'right',
        }]"
      >
        <slot :toggle="toggle" />
      </div>
    </transition>
  </div>
</template>

<script setup>
/**
 * DEPRECATED
 * Use `src-spa/components/shared/DropdownMenu.vue` Dropdown from floating-vue directly
 */
import _debounce from 'lodash/debounce'
import { ref, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  align: {
    type: String,
    default: 'left',
    validator: val => ['left', 'center', 'right'].includes(val),
  },
  dropdownClass: { type: [String, Array, Object], default: '' },
  dark: Boolean,
  hover: Boolean,
})

const containerRef = ref()
const open = ref(false)

const debounceHide = _debounce(() => {
  open.value = false
}, 200)

function mouseleave () {
  if (props.hover) debounceHide()
}

function mouseover () {
  if (open.value) debounceHide.cancel()
}

onMounted(() => document.addEventListener('click', onOutsideClick))
onBeforeUnmount(() => document.removeEventListener('click', onOutsideClick))
function onOutsideClick (event) {
  if (!open.value) return
  if (containerRef.value.contains(event.target)) return
  open.value = false
}

function toggle () {
  open.value = !open.value
}

function show () {
  open.value = true
}
</script>
